import { AgGridReact } from 'ag-grid-react';
import React, { useState, useEffect, useMemo, useRef } from 'react';
import './styles.css';
import { collection, query, onSnapshot } from "firebase/firestore";
import { db } from './firebase';
import { ImageCellRendererHorseName, getCellStyle, getRowStyle } from './utility';
import MyContext from './MyContext';
import { useNavigate } from "react-router-dom";
import OddsHeader from './OddsHeader';
import { ImageCellRenderer } from './utility';


function OddsUser({ raceNumber, venue, oddsDateValue }) {
    const navigate = useNavigate();
    const [gridApi, setGridApi] = useState(null);
    const [pageName, setPageName] = useState();
    const [rowData, setRowData] = useState([]);
    const [columnDefs, setColumnDefs] = useState([]);
    const [horseStatus, sethorseStatus] = useState();
    const [textInputValue, settextInputValue] = useState();
    const [checked, setChecked] = useState(true);
    const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
    const gridApiRef = useRef(null);
    const defaultColDef = useMemo(() => {
        return {
            width: 170,
            resizable: true,
            sortable: true
        };
    }, []);


    const cellCount = 50;
    const generatedCode = [
        {
            maxWidth: 100,
            id: 0,
            headerName: 'Name',
            field: 'horseName',
            hide: !checked,
            rowSpan: (params) => {
                const rowIndex = params.node.rowIndex;
                return rowIndex % 2 === 0 ? 2 : 1;
            },
            cellStyle: {
                backgroundColor: '#FFF',
            },
            cellClassRules: {
                'custom-border-class': (params) => params.column.getColId() === 'horseName', // Apply border class for column 1
            },
            cellRendererFramework: ImageCellRendererHorseName,

        },
        {
            maxWidth: 60,
            id: 1,
            headerName: '',
            field: 'horseNumber',
            rowSpan: (params) => {
                const rowIndex = params.node.rowIndex;
                return rowIndex % 2 === 0 ? 2 : 1;
            },
            cellStyle: (params) => {
                const backgroundColor = params.data.horseColor;
                return { backgroundColor };
            },
            cellClassRules: {
                'custom-border-class': (params) => params.column.getColId() === 'horseNumber', // Apply border class for column 1
            },
            cellRendererFramework: ImageCellRenderer,
        },
        {
            maxWidth: 30,
            hide: false,
            id: 2,
            field: 'MO',
            editable: true,
            suppressNavigable: true,
            suppressMovable: true,
        },
        {
            maxWidth: 30,
            hide: false,
            id: 3,
            field: 'NO',
            suppressNavigable: true,
            suppressMovable: true,
            // cellStyle: params => getCellStyle(params, gridApiRef, 'pos')
        },
        {
            maxWidth: 30,
            hide: false,
            id: 4,
            field: 'OO',
            suppressNavigable: true,
            suppressMovable: true,
            // cellStyle: params => getCellStyle(params, gridApiRef, 'pos')
        },
        {
            maxWidth: 30,
            hide: false,
            id: 5,
            field: 'pos',
            cellStyle: params => getCellStyle(params, gridApiRef, 'pos')
        }];

    for (let i = 1; i <= cellCount; i++) {
        const field = `col${i}`;
        generatedCode.push({
            id: i + 2,
            headerName: '',
            maxWidth: 30,
            field: field,
            suppressNavigable: true,
            suppressMovable: true,
            cellStyle: params => getCellStyle(params, gridApiRef, field)
        });
    }

    useEffect(() => {
        setColumnDefs(generatedCode);
    }, [checked])

    useEffect(() => {
        if (!oddsDateValue) return navigate("/home");
        setPageName(oddsDateValue + "Race" + raceNumber);
    }, [oddsDateValue, raceNumber]);

    useEffect(() => {
        let filteredData = []
        const q = query(collection(db, pageName + "myData"));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const rows = [];
            querySnapshot.forEach((doc) => {
                rows.push(doc.data());
            });
            let maxFilledColn = -1;
            let maxFilledColnKey = '';
            rows.forEach((obj) => {
                if (obj["pos"] === "W") {
                    Object.keys(obj).forEach((key) => {
                        // Extract the coln index from the key (assuming the key format is 'colX')
                        const colnIndex = parseInt(key.match(/\d+/));

                        // Check if the value of the key is not null and the coln index is greater than the current maximum
                        if (obj[key] !== undefined && obj[key] !== null && obj[key] !== '' && colnIndex > maxFilledColn) {
                            maxFilledColn = colnIndex;
                            maxFilledColnKey = key;
                        }
                    });
                }
            });
            // if (parseInt(maxFilledColnKey.match(/\d+/)) <= 8) {
            rows.sort((a, b) => {
                const valueA = a[maxFilledColnKey];
                const valueB = b[maxFilledColnKey];

                // Handle null or undefined values by placing them at the end of the sorted array
                if (valueA === null || valueA === undefined) return 1;
                if (valueB === null || valueB === undefined) return -1;

                // Sort in ascending order based on the float value
                return valueA - valueB;
            });
            // }
            // Print the sorted array of objects
            filteredData = rows.filter(item => !item.hasOwnProperty("parent"));
            const removedKeys = rows.filter(item => item.hasOwnProperty("parent"));
            removedKeys.forEach(removedKey => {
                const parentIndex = filteredData.findIndex(item => item.horseNumber === removedKey.parent);
                if (parentIndex !== -1) {
                    filteredData.splice(parentIndex + 1, 0, removedKey);
                }
            });
            setRowData(filteredData);
        });

        const horseStatusFb = query(collection(db, pageName + "Status"));
        const unsubscribeHorseStatus = onSnapshot(horseStatusFb, (querySnapshot) => {
            const horseStatusFb = [];
            querySnapshot.forEach((doc) => {
                horseStatusFb.push(doc.data());
            });
            sethorseStatus(horseStatusFb[0]?.horseStatusData);
        });

        const textInputValueFb = query(collection(db, pageName + "StatusText"));

        const unsubscribeStatusText = onSnapshot(textInputValueFb, (querySnapshot) => {
            const horseStatusTextFb = [];
            querySnapshot.forEach((doc) => {
                horseStatusTextFb.push(doc.data());
            });
            settextInputValue(horseStatusTextFb[0]?.text);
        });



        return () => {
            unsubscribe();
        };
    }, [pageName])

    const onGridReady = (params) => {
        setGridApi(params.api);
    };

    return (
        <>
            <OddsHeader
                venue={venue}
                pageName={pageName}
                horseStatus={horseStatus}
                checked={checked}
                textInputValue={textInputValue}
                setChecked={setChecked}

            // handleColumnVisibilityChange={handleColumnVisibilityChange}
            />

            <div className="ag-theme-alpine" style={{ height: 800, width: '100%' }}>
                <div style={containerStyle}>
                    <div style={gridStyle} className="ag-theme-alpine">
                        <AgGridReact
                            ref={gridApiRef}
                            rowData={rowData}
                            columnDefs={columnDefs}
                            defaultColDef={defaultColDef}
                            suppressRowTransform={true}
                            onGridReady={onGridReady}
                            getRowStyle={getRowStyle}
                            rowHeight={25}
                            headerHeight={20}
                        ></AgGridReact>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OddsUser;
