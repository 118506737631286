import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Checkbox, FormControlLabel, IconButton } from '@mui/material';
import { blue } from '@mui/material/colors';
import CloseIcon from '@mui/icons-material/Close';
import {
    getFirestore,
    query,
    getDocs,
    collection,
    where,
    doc,
    setDoc,
    addDoc

} from "firebase/firestore";
import { auth } from './firebase';
import { db } from './firebase';

function loadScript(src) {
    return new Promise((resolve) => {
        const script = document.createElement('script')
        script.src = src
        script.onload = () => {
            resolve(true)
        }
        script.onerror = () => {
            resolve(false)
        }
        document.body.appendChild(script)
    })
}

async function displayRazorpay(totalPrice, checkedItems) {
    const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')

    if (!res) {
        alert('Razropay failed to load!!')
        return
    }

    const data = await fetch('http://127.0.0.1:5001/ashwavaarte-ae740/us-central1/razorpay', {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            "data": {
                "amt": totalPrice,
                "receipt": "order_rcptid_11"
            }
        }),
    }).then((t) =>
        t.json()
    )

    const options = {
        "key": "rzp_test_iIdx1RpT9wGKhC", // Enter the Key ID generated from the Dashboard
        "amount": "50000", // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        "currency": "INR",
        "name": "Acme Corp",
        "description": "Test Transaction",
        "image": "https://example.com/your_logo",
        "order_id": data.result.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        // "callback_url": "http://localhost:1769/verify",
        "notes": {
            "address": "Razorpay Corporate Office"
        },
        "theme": {
            "color": "#3399cc"
        },
        "handler": async function (response) {
            await addDoc(collection(db, "paymentDetails"), {
                uid: auth.currentUser.uid,
                checkedItems,
                timestamp: new Date()
            });
            // alert(response.razorpay_payment_id);
            // alert(response.razorpay_order_id);
            // alert(response.razorpay_signature);

        },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
}

const PaymentDialog = ({ jsonData, open, onClose }) => {
    const [checkedItems, setCheckedItems] = useState({});
    const [totalPrice, setTotalPrice] = useState(0);



    useEffect(() => {
        calculateTotalPrice();
    }, [checkedItems]); // Calculate total price whenever checkedItems changes

    const handleCheckboxChange = (itemId) => (event) => {
        setCheckedItems({ ...checkedItems, [itemId]: event.target.checked });
    };

    const calculateTotalPrice = () => {
        let total = 0;
        jsonData.forEach((item) => {
            if (checkedItems[item.id]) {
                total += item.amount;
            }
        });
        setTotalPrice(total);
    };

    const handleApiCall = () => {
        displayRazorpay(totalPrice, checkedItems);
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle style={{ backgroundColor: blue[500], color: 'white', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                Choose Plan
                <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                {jsonData.map((item) => (
                    <div key={item.id} style={{ display: 'flex', alignItems: 'center' }}>
                        <FormControlLabel
                            control={<Checkbox checked={checkedItems[item.id] || false} onChange={handleCheckboxChange(item.id)} />}
                            label={item.name}
                            style={{ color: blue[500] }}
                        />
                    </div>
                ))}
            </DialogContent>
            <DialogActions style={{ flexDirection: 'column', alignItems: 'stretch' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
                    <span style={{ fontWeight: 'bold' }}>Total Price: {totalPrice} {jsonData[0]?.currency}</span>
                </div>
                <Button onClick={handleApiCall} color="primary" variant="contained" fullWidth>
                    Pay Now
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PaymentDialog;
