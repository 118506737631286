import React, { useState } from 'react';

const CustomDropdownComponent = ({ value, data, onValueChange }) => {
    
    const [selectedValue, setSelectedValue] = useState(value);
    const handleValueChange = (event) => {
        const newValue = event.target.value;
        setSelectedValue(newValue);
        onValueChange(newValue);
    };

    return (
        <select value={selectedValue} onChange={handleValueChange}>
            {data.map((option,index) => (
                <option key={index} value={option}>
                    {option}
                </option>
            ))}
        </select>
    );
};

export default CustomDropdownComponent;
