import horseImg from './logo8.png';
import scoreImg from './02.png';
import './styles.css';
import { Card, CardContent, Typography } from '@mui/material';
import CircularCard from './CircularCard';
import CircularCardOdds from './CircularCardOdds';
import ElevatedBackgroundCard from './ElevatedBackgroundCard';
import ElevatedBackgroundCardHorseName from './ElevatedBackgroundCardHorseName';

export const getCellStyle = (params, gridApiRef, field) => {
    const cellStyle = {};
    const { node, column, columnApi } = params;
    const currentRow = node;
    const colId = column.getId();
    const currentIndex = currentRow.rowIndex;
    const currentColumnIndex = columnApi.getAllColumns().findIndex(col => col.getColId() === colId);
    const prevColumnId = columnApi.getAllColumns()[currentColumnIndex - 1].getId();
    const rowData = gridApiRef.current.api.getDisplayedRowAtIndex(currentIndex)?.data;
    const leftCellData = rowData[prevColumnId];
    const currentValue = params.value;
    const isCurrentValueRepeated = parseFloat(currentValue) === parseFloat(leftCellData);


    const isCurrentValueValid = !isNaN(parseFloat(currentValue)) && isFinite(currentValue);
    const isLeftValueValid = !isNaN(parseFloat(leftCellData)) && isFinite(leftCellData);
    if (isCurrentValueValid && isLeftValueValid) {
        if (parseFloat(currentValue) > parseFloat(leftCellData)) {
            cellStyle.color = '#FF0000';
        } else if (parseFloat(currentValue) < parseFloat(leftCellData)) {
            cellStyle.color = '#0F812F';
        } else if (isCurrentValueRepeated) {
            let currColIndex = columnApi.getAllColumns().findIndex(col => col.getColId() === colId);
            while (currColIndex > 1) {
                const prevColumnId = columnApi.getAllColumns()[currColIndex].getId();
                const leftCellVal = rowData[prevColumnId];
                if (parseFloat(currentValue) !== parseFloat(leftCellVal)) {
                    cellStyle.color = parseFloat(currentValue) < parseFloat(leftCellVal) ? '#0F812F' : (parseFloat(currentValue) > parseFloat(leftCellVal) ? '#F32E0D' : '');
                    break;
                }
                currColIndex--;
            }
        }
    }

    if (colId === 'pos') {
        // cellStyle.border = '1px solid black';
    }

    // const newCellStyle = {
    //     ...cellStyle,
    //     'font-size' : '15px'
    // }
    return cellStyle;
};


export function rowSpan(params, rowData) {
    var horseNumber = params.data ? params.data.horseNumber : undefined;
    const uniqueWholeNumbers = [...new Set(rowData.map(row => row.horseNumber))]
        .filter(value => /^\D+(\d+)$/.test(value));
    if (uniqueWholeNumbers.includes(horseNumber)) {
        return 2;
    }
    else {
        return 1;
    }
}


export function generateCellSpanCondition(rowData) {

    const uniqueWholeNumbers = [...new Set(rowData.map(row => row.horseNumber))]
        .filter(value => /^\D+(\d+)$/.test(value));
    const condition = uniqueWholeNumbers.map(value => `value === '${value}'`).join(' || ');
    return '"' + condition + '"';
}


export const getRowStyle = (params) => {
    const cellStyle = {};
    const rowIndex = params.node.rowIndex;
    if ((rowIndex + 1) % 2 === 0) {
        cellStyle.borderBottom = '1px solid black';
        cellStyle.borderRight = '1px solid black';
    } else if ((rowIndex + 1) % 2 === 1) {
        cellStyle.borderTop = '1px solid black';
        cellStyle.borderRight = '1px solid black';
    }
    return cellStyle;
};

export const ImageCellRenderer = (props) => {
    const horseNumber = parseInt(props?.data?.horseNumber.match(/\d+/)[0])
    return (
        <div className="image-cell">
            <img src={horseImg} alt="Background" className="image-cell__background" style={{}} />
            <div className="image-cell__number">{horseNumber}</div>
        </div>
    );
};

function hasAsterisk(numbersList) {
    for (let number of numbersList) {
        if (number.includes('*')) {
            return true;
        }
    }
    return false;
}


export const ImageCellRendererScore = (props) => {
    const result = props?.data?.score ? hasAsterisk(props?.data?.score) : 0;
    const customCardColor = props?.data?.horseColor; // Orange color
    const customTextColor = '#000'; // Black color
    const customBorderColor = '#2196F3'; // Blue color for the border
    return (
        // result ? (
        //     <div className="image-cell-score">
        //         <img src={scoreImg} alt="Background" className="image-cell__background-score" style={{ /* Add any specific styles here */ }} />
        //         <div className="image-cell__number-score">{props?.data?.score?.replace(/\*/g, '')}</div>
        //     </div>
        // ) : (
        //     <div>{props?.data?.score?.replace(/\*/g, '')}</div>
        // )
        result ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                <CircularCard text={props?.data?.score?.replace(/\*/g, '')} cardColor={customCardColor} textColor={customTextColor} borderColor={customBorderColor} />
            </div>
        ) : (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                <CircularCard text={props?.data?.score?.replace(/\*/g, '')} cardColor={customCardColor} textColor={customTextColor} />
            </div>
        )
    );
};

export const ImageCellRendererPaddockOpinion = (props) => {
    const customCardColor = props?.data?.paddockColor || '#FFF'; // Orange color
    const customTextColor = '#000'; // Black color
    const customBorderColor = '#000'; // Blue color for the border
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
            <ElevatedBackgroundCard text={props?.data?.paddockOpinion} cardColor={customCardColor} textColor={customTextColor} borderColor={customBorderColor} />
        </div>
    )
}

export const ImageCellRendererHorseName = (props) => {
    const customCardColor = props?.data?.horseColor || '#FFF'; // Orange color
    const customTextColor = '#000'; // Black color
    const customBorderColor = '#000'; // Blue color for the border
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
            <ElevatedBackgroundCardHorseName text={props?.data?.horseName} cardColor={customCardColor} textColor={customTextColor} borderColor={customBorderColor} />
        </div>
    )
}

export const ImageCellRendererOpeningOdds = (props) => {
    const oo = props?.data?.OO;
    return (
        oo && (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CircularCardOdds text={oo} cardColor='blue' textColor='white' borderColor='#000' />
            </div>
        )
    );
};
