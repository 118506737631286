


const convertStringToRace = (str) => {
  if (typeof str === 'undefined') {
    return null;
  }
  const firstEightChars = str.substring(0, 8);
  const formattedString = str.substring(8);
  const day = firstEightChars.substring(0, 2);
  const month = firstEightChars.substring(2, 4);
  const year = firstEightChars.substring(4);
  return ` ${day}/${month}/${year} `  + formattedString;
};

export default convertStringToRace;
