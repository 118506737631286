import React from 'react';

function RefundPolicy() {
    const cardStyle = {
        backgroundColor: '#fff', // Card background color
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)', // Shadow for card effect
        margin: '20px auto', // Margin for center alignment
        padding: '50px', // Padding inside the card
        maxWidth: '800px', // Maximum width of the card
        textAlign: 'left', // Align text to left within the card
        borderRadius: '10px' // Rounded corners for the card
    };
    const styles = {
        list: {
            listStyleType: 'none', // Removes default list styling
            padding: 0,
            color: '#333', // Example text color, adjust as needed
            fontFamily: 'Arial, sans-serif'
        },
        listItem: {
            marginBottom: '10px', // Spacing between list items
            fontSize: '16px', // Adjust font size as needed
        },
        bulletPoint: {
            fontWeight: 'bold', // Makes the bullet point number bold
            marginRight: '5px' // Spacing between the bullet point and text
        },
        container: {
            maxWidth: '800px',
            margin: 'auto',
            fontFamily: 'Arial, sans-serif',
            color: '#333'
        },
        header: {
            color: '#2a9d8f',
            textAlign: 'center',
            marginBottom:'10px'
        },
        subHeader: {
            color: '#264653',
            textAlign: 'center',
            marginBottom:'10px'
        },
        sectionHeader: {
            marginTop:'10px',
            color: '#e76f51',
            marginBottom:'10px'
        },
        paragraph: {
            textAlign: 'justify',
            lineHeight: 1.6
        }
    };
    return (
        <div style={cardStyle}>
            <h1 style={styles.header}>Ashwa Vaarte</h1>
            <h2 style={styles.subHeader}>Refund & Return Policy</h2>
            <p style={styles.paragraph}>Welcome to Ashwa Vaarte! We specialize in offering digital service for the horse racing industry, such
                as Fluctuating Data (Odds), Calculated Data (Handicap Scores), Analysis of Race & Animals, their racing
                history, health conditions etcetera. This Refund Policy is designed to ensure a clear understanding of
                our process regarding refunds</p>

            <h3 style={styles.sectionHeader}>Digital Product/Service Nature</h3>
            <p style={styles.paragraph}>Our products/services are digital and delivered electronically. Unlike physical goods, they cannot be
                returned. Hence, we encourage our customers to know some of the facts of Ashwa Vaarte’s Refund
                Policy.
                As you may know we are a team of horse racing experts who specialise with a unique platform that
                helps you to make your final selections by granting you the necessary insights and information on the
                horse racing. Our research team have concluded that there are three key factors for narrowing down
                the final decision after contacting professionals in the racing sector and successful punters. And those
                3 key factors are:
                <ul style={styles.list}>
                    <li style={styles.listItem}><span style={styles.bulletPoint}>No 1:</span> Handicapping</li>
                    <li style={styles.listItem}><span style={styles.bulletPoint}>No 2:</span> Paddock looks and race day fitness</li>
                    <li style={styles.listItem}><span style={styles.bulletPoint}>No 3:</span> Market trend which is the fluctuations in odds</li>
                </ul>
                In order to access the above given real-time vital information you would have to sign-up and subscribe
                to our website. The subscription done will give you access towards real-time odds fluctuation,
                handicap scores for each race animal, paddock looks, race-day fitness, pre-race analysis, post paddock
                analysis and much more. The subscriptions start at a very low price of ₹ 100/day.
                Subscribing to our website will enable you to learn the most efficient and effective way of horse race
                betting. You will also be able to see the real-time fluctuating data which is useful when you are about
                to place a wager on the animal of your choice. Additionally, you will be shown the formulated and
                calculated data of handicap points assigned to each horse. To access all these live race information,
                you will have to subscribe to our website.
            </p>

            <h3 style={styles.sectionHeader}>Refund Policy</h3>
            <p style={styles.paragraph}>The services that we provide is based on the live races that happen across the cities of Bengaluru,
                Mysore, Mumbai, Pune, Hyderabad, Kolkata, Delhi, Chennai & Ooty. The data provided to you on our
                website comes a cost and the payment should be made through the different payment modes available
                on our website. We strictly adhere to “All Sales are Final Policy” and “No Cash Refund Policy”. Which
                means, “The transaction that you have successfully completed in order to subscribe to our services is
                final and no cash refund shall be given in any circumstances.
                All services of Ashwa Vaarte are Non-Refundable, but we are more than happy to be giving you an
                extension in your subscription for the very next race day. Here are some of the situations where an
                extension on your subscription shall be processed, such as:
                <ul style={styles.list}>
                    <li style={styles.listItem}>If the entire races of the particular day have been completed abandoned due to any and
                        whatsoever reasons from the race clubs;</li>
                    <li style={styles.listItem}>If the races have been abandoned in the middle of the race day due to any and whatsoever
                        reasons from the race clubs (only if more than 70% of the races have been cancelled);
                    </li>
                    <li style={styles.listItem}>If there are any technical issues on our website (please do contact us immediately in such
                        cases) and if we are unable to provide our services on time for the entire day;
                    </li>
                </ul>
                And in such cases, cash refund shall not be given. But your subscription shall be extended till the next
                consecutive race day for which you do not need to pay.
            </p>


            <h3 style={styles.sectionHeader}>No Refund Policy</h3>
            <p style={styles.paragraph}>
                As we already have mentioned the criteria for an extension of your subscription, refund request shall
                not be processed for the below mentioned reasons as well:
            </p>
            <ul style={styles.list}>
                <li style={styles.listItem}>If the races have been abandoned in the middle of the race day due to any and whatsoever
                    reasons from the race clubs but more than 3 races have completed, extension of subscription
                    or refund is not applicable</li>
                <li style={styles.listItem}>We are not responsible for the bets that you will be placing, as we are not a betting website.
                    Even after us giving clear instructions on the Pre-Race Analysis audio, you make any and all
                    bets, we are not responsible and an extension or refund is not applicable.</li>
                <li style={styles.listItem}>As we have mentioned we strictly adhere to “All Sales are Final Policy” and for whatsoever
                    reason you are unable to attend or indulge in the races for the day and would like to cancel
                    the subscription, an extension or refund is not applicable. </li>
            </ul>

            <h3 style={styles.sectionHeader}>Updates to Refund Policy</h3>
            <p style={styles.paragraph}>
                We reserve the right to modify, alter or update this refund policy from time to time, in our sole
                discretion and the most current version will always be posted on our website.

            </p>

            <h3 style={styles.sectionHeader}>Applicable Law</h3>
            <p style={styles.paragraph}>
                The validity and interpretation of this Refund Policy shall be governed by the laws of the state of
                Karnataka, under the Consumer Protection Act, 2019 and Information Technology Amendment Act,
                2008.


            </p>

            <h3 style={styles.sectionHeader}>Acknowledgement</h3>
            <p style={styles.paragraph}>
                By subscribing to our digital services, you have agreed and acknowledged that you have read the terms
                of the refund policy and abide by the same.

            </p>
        </div>
    );
}


export default RefundPolicy;
