import React from 'react';
import { Card, Grid, Button, Typography, CardContent, CardActions } from '@mui/material';

const plans = [
    {
        title: "Silver",
        color: "#A9B1B7",
        price: "Rs 50/day + 18% GST",
        amount: "59",
        features: [{ name: "Paddock opinion only", strikethrough: false },
            // { name: "View Odds", strikethrough: true },
            // { name: "Selections", strikethrough: true }
        ]
    },
    {
        title: "Gold",
        color: "#FFC107",
        price: "Rs 100/day + 18% GST",
        amount: "118",
        features: [
            { name: "Odds only", strikethrough: false },
            // { name: "Paddock Opinion", strikethrough: true },
            // { name: "Selections", strikethrough: true }
        ]
    },
    {
        title: "Diamond",
        color: "#00BFFF",
        amount: "295",
        price: "Rs 250/day + 18% GST",
        features:
            [
                { name: "Paddock Opinion", strikethrough: false },
                { name: "Odds", strikethrough: false },
                { name: "Selections", strikethrough: false }
            ]
    }
];


const PricingPlans = ({ handlePayment, index }) => {

    if (index === undefined) {
        return (<></>);
    } else {
        return (
            <Grid container spacing={2} justifyContent="center">
                {/* {plans.map((plan, index) => ( */}
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Typography variant="h5" component="h2" style={{ textAlign: 'center', color: plans[index]?.color }}>{plans[index]?.title}</Typography>
                            {plans[index]?.price}
                            <ul>
                                {plans[index]?.features.map((feature, idx) => (
                                    <li key={idx}>
                                        <Typography variant="body1" style={feature.strikethrough ? { textDecoration: 'line-through' } : {}}>
                                            {feature.name}
                                        </Typography>
                                    </li>
                                ))}
                            </ul>
                        </CardContent>
                        <CardActions>
                            <Button variant="contained"
                                style={{ backgroundColor: plans[index]?.color, color: '#ffffff' }}
                                color="primary"
                                fullWidth
                                onClick={() => handlePayment(plans[index]?.amount)}>
                                Pay Now
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
                {/* ))} */}
            </Grid>
        );
    }

};

export default PricingPlans;
