import React from 'react'
import {
    InfoContainer,
    InfoWrapper,
    InfoRow,
    BtnWrap,
    Column1,
    Column2,
    Column3,
    Heading,
    ImgWrap,
    Subtitle,
    TextWrapper,
    TopLine,
    Img
} from '../Info/InfoElements'
import ReactPlayer from 'react-player'
import { Typography } from '@mui/material';

import { Button } from '../../Utilities/ButtonElements'

const InfoSection = ({
    lightBg,
    imgStart,
    topLine,
    lightText,
    darkText,
    headline,
    description,
    buttonLabel,
    alt,
    img,
    id,
    primary,
    dark,
    dark2
}) => {

    return (
        <>
            <InfoContainer lightBg={lightBg} id={id}>
                <InfoWrapper>
                <Heading lightText={lightText}>{headline}</Heading>
                    <InfoRow imgStart={imgStart}>
                        <Column1>
                        <ReactPlayer url='https://youtu.be/m4Jz2va38bc' width={300} height={300}/>
                        <Typography variant="subtitle1" color="black">
                        Ashwa Vaarte | Instructional Video English
                        </Typography>
                            {/* <TextWrapper>
                                <TopLine>{topLine}</TopLine>
                                <Heading lightText={lightText}>{headline}</Heading>
                                <Subtitle darkText={darkText}>{description}</Subtitle><br />
                                <BtnWrap>
                                    <Button to="home"
                                        smooth={true}
                                        duration={500}
                                        spy={true}
                                        exact={true}
                                        offset={-80}
                                        primary={primary ? 1 : 0}
                                        dark={dark ? 1 : 0}
                                        dark2={dark2 ? 1 : 0}
                                    >{buttonLabel}</Button>
                                </BtnWrap>
                            </TextWrapper> */}

                        </Column1>
                        <Column2>
                        <ReactPlayer url='https://youtu.be/etpJfLCclo4' width={300} height={300}/>
                        <Typography variant="subtitle1" color="black">
                        Ashwa Vaarte | Instructional Video | ಕನ್ನಡ
                        </Typography>
                            {/* <ImgWrap>
                                <Img src={img} alt={alt} />
                            </ImgWrap> */}
                        </Column2>
                        <Column3>
                        <ReactPlayer url='https://youtu.be/9TFdey6849o' width={300} height={300}/>
                        <Typography variant="subtitle1" color="black">
                        Ashwa Vaarte | Instructional Video | हिन्दी
                        </Typography>
                        </Column3>
                    </InfoRow>
                </InfoWrapper>
            </InfoContainer>
        </>
    )
}

export default InfoSection
