import React from 'react';

function TermsAndConditions() {
  const cardStyle = {
    backgroundColor: '#fff',
    borderRadius: '8px',
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
    padding: '30px',
    margin: '20px',
    maxWidth: '700px',
    lineHeight: '1.6',
  };

  const styles = {
    header: {
        color: '#2a9d8f',
        textAlign: 'center',
        marginBottom:'10px'
    },
    subHeader: {
        color: '#264653',
        textAlign: 'center',
        marginBottom:'10px'
    },
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: '#f0f0f0' }}>
      <div style={cardStyle}>
        <h1 style={styles.header}>Ashwa Vaarte</h1>
        <h2 style={styles.subHeader}>Terms & Conditions</h2>
        <p>Gambling is a form of entertainment and can be highly addictive. Set a budget and please play responsibly.</p>
        <p>Please read this Disclaimer and the Terms & Conditions, Privacy Policy, Cookie Policy carefully. If you do not agree to this Disclaimer or the Terms & Conditions, Privacy Policy, and Cookie Policy, please exit this Web Site, the Application, and the Platform and any Web Site affiliated with the Platform and discontinue your further use of each of them.</p>
        <p>Ashwa Vaarte is not a gambling operator or sports betting operator, and none of its affiliates or subsidiaries constitute gambling operators or sports betting operators. Ashwa Vaarte (including its affiliates and subsidiaries) operates independently from any gambling operator’s or sports betting operator’s control. We nor any of the representatives of our brand do not ask for any money and we highly request you not to pay anyone who come bearing the Ashwa Vaarte name and ask for betting money. We are an informative website, providing precise, calculated & formulated data in order for our patrons to learn more on horse racing.</p>
        <p>We are trying to pull people away from unnecessary, unstable & highly addictive, risky gambling habit and guide them towards a well-trained, mathematical & statistical way of finding the horse. The services that we provide are based on years of research, calculation & dedication, for which we are charging a nominal fee. Please read these terms and conditions carefully. We always advice our patrons to watch the paddock & the market trends before making any final decision.</p>
        <p>Ashwa Vaarte maintains no responsibility for customer service issues that are particular to any gambling operator, or online gambling or sports betting information or activities. Moreover, Ashwa Vaarte does not serve as a clearinghouse for specific customer complaints relating to such matters. You are advised to directly contact the relevant gambling operators and gaming establishments using the contact information that has been provided to you by them in connection with any such inquiries or matters that you may have.</p>
      </div>
    </div>
  );
}

export default TermsAndConditions;
