import * as Yup from "yup";
import { useState, useEffect } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import { useNavigate } from "react-router-dom";
import {
  Stack,
  Box,
  TextField,
  IconButton,
  InputAdornment,
  Checkbox,
  FormControlLabel,
  FormControl,
  FormHelperText
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Icon } from "@iconify/react";
import { motion } from "framer-motion";
import { registerWithEmailAndPassword, auth } from "./firebase";
import { useAuthState } from "react-firebase-hooks/auth";
/////////////////////////////////////////////////////////////
let easing = [0.6, -0.05, 0.01, 0.99];
const animate = {
  opacity: 1,
  y: 0,
  transition: {
    duration: 0.6,
    ease: easing,
    delay: 0.16,
  },
};

const SignupForm = () => {
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (loading) return;
    if (user) navigate("/home");
  }, [user, loading, navigate]);


  const SignupSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("First name required"),
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
    phoneNumber: Yup.string()
      .matches(
        /^\d{10}$/,
        'Please enter a valid 10-digit phone number'
      )
      .required('Phone number is required'),
    isAgeConfirmed: Yup.boolean().oneOf([true], 'You must confirm that you are 18 years or older').required(),
    isAgreementConfirmed: Yup.boolean().oneOf([true], 'You must agree to the terms and conditions').required(),
  });


  const formik = useFormik({
    initialValues: {
      firstName: "",
      // lastName: "",
      phoneNumber: "",
      email: "",
      isAgeConfirmed: false,
      isAgreementConfirmed: false,
      password: "",
    },
    validationSchema: SignupSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const signUp = await registerWithEmailAndPassword(values.firstName, values.email, values.password, values.phoneNumber);
        if (signUp) {
          await auth.signOut();
          navigate("/login");
        }

      } catch (err) {
        alert(err.message);

      } finally {
        setSubmitting(false);
      }
    },
  });
  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldTouched, setFieldValue } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Stack
            component={motion.div}
            initial={{ opacity: 0, y: 60 }}
            animate={animate}
            direction={{ xs: "column", sm: "row" }}
            spacing={2}
          >
            <TextField
              fullWidth
              label="First name"
              {...getFieldProps("firstName")}
              error={Boolean(touched.firstName && errors.firstName)}
              helperText={touched.firstName && errors.firstName}
            />


          </Stack>

          <Stack
            spacing={3}
            component={motion.div}
            initial={{ opacity: 0, y: 40 }}
            animate={animate}
          >

            <TextField
              fullWidth
              label="Phone Number"
              {...getFieldProps("phoneNumber")}
              error={Boolean(touched.phoneNumber && errors.phoneNumber)}
              helperText={touched.phoneNumber && errors.phoneNumber}
              inputProps={{ inputMode: 'numeric' }}
            />
            <TextField
              fullWidth
              autoComplete="username"
              type="email"
              label="Email address"
              {...getFieldProps("email")}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />

            <TextField
              fullWidth
              autoComplete="current-password"
              type={showPassword ? "text" : "password"}
              label="Password"
              {...getFieldProps("password")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={() => setShowPassword((prev) => !prev)}
                    >
                      <Icon
                        icon={
                          showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                        }
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />
            <FormControl error={
              touched.isAgeConfirmed && Boolean(errors.isAgeConfirmed)
            }>
              <FormControlLabel
                required
                control={<Checkbox checked={formik.values.isAgeConfirmed}
                  onChange={(e) => {
                    setFieldTouched("isAgeConfirmed", e.target.checked);
                    setFieldValue("isAgeConfirmed", e.target.checked);
                  }
                  } />}
                label="I confirm I am 18 years or older"

              />
              <FormHelperText>
                {touched.isAgeConfirmed && errors.isAgeConfirmed}
              </FormHelperText>
            </FormControl>

            <FormControl error={
              touched.isAgreementConfirmed &&
              Boolean(errors.isAgreementConfirmed)
            }>
              <FormControlLabel
                required
                control={<Checkbox checked={formik.values.isAgreementConfirmed}
                  onChange={(e) => {
                    setFieldTouched("isAgreementConfirmed", e.target.checked);
                    setFieldValue("isAgreementConfirmed", e.target.checked);
                  }
                  } />}
                label={
                  <span>
                    I agree to{' '}
                    <a target="_blank" rel="noopener noreferrer" href="https://www.ncpgambling.org/programs-resources/responsible-gambling/irgs/#:~:text=Responsible%20gambling%20is%20policies%20and,referrals%20to%20treatment%20and%20recovery">Responsible Gambling</a>
                  </span>
                }
              />
              <FormHelperText>
                {
                  touched.isAgreementConfirmed &&
                  errors.isAgreementConfirmed
                }
              </FormHelperText>
            </FormControl>
          </Stack>

          <Box
            component={motion.div}
            initial={{ opacity: 0, y: 20 }}
            animate={animate}
          >
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              disabled={!formik.isValid || isSubmitting}
            >
              Sign up
            </LoadingButton>
          </Box>
        </Stack>
      </Form>
    </FormikProvider>
  );
};

export default SignupForm;