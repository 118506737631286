import { initializeApp } from "firebase/app"
import { signInWithEmailAndPassword, getAuth, createUserWithEmailAndPassword, setPersistence, browserSessionPersistence, sendPasswordResetEmail } from "firebase/auth"
import {
    getFirestore,
    query,
    getDocs,
    collection,
    where,
    doc,
    setDoc,
    addDoc

} from "firebase/firestore";
import { getDatabase, ref, onValue, onDisconnect, set } from "firebase/database";
import { getStorage } from "firebase/storage";
import { serverTimestamp } from 'firebase/database'


const firebaseConfig = {
    apiKey: "AIzaSyDqB6x-WBDk3FJivBnBJBxde_SGLppx9VY",
    authDomain: "ashwavaarte-ae740.firebaseapp.com",
    databaseURL: "https://ashwavaarte-ae740-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "ashwavaarte-ae740",
    storageBucket: "ashwavaarte-ae740.appspot.com",
    messagingSenderId: "76539786593",
    appId: "1:76539786593:web:4feeaed0f31ebef07e0120",
    measurementId: "G-VNQ867RF0R"
};
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);


const database = getDatabase();
const storage = getStorage();;

const logInWithEmailAndPassword = async (email, password) => {
    try {
        await new Promise((resolve, reject) => {
            setPersistence(auth, browserSessionPersistence)
                .then(() => resolve())
                .catch((error) => reject(error));
        });

        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        const user = auth.currentUser;

        try {
            const transDate = new Date().toLocaleDateString("en-GB").replace(/\//g, "");
            const userRef = doc(db, "usersLoginDetails", transDate);
            await setDoc(userRef, {
                loggedInUid: user.uid
            });
        } catch (error) {
            console.log(error);
        }
        return userCredential;
    } catch (err) {
        throw err;
    }
};


const registerWithEmailAndPassword = async (name, email, password, phoneNumber) => {
    try {
        const res = await createUserWithEmailAndPassword(auth, email, password);
        const user = res.user;
        await addDoc(collection(db, "users"), {
            uid: user.uid,
            admin: 0,
            name,
            authProvider: "local",
            email,
            phoneNumber: phoneNumber,
            enable: 1,
            date: new Date()
        });
        return true; // Registration success
    } catch (err) {
        throw err;
    }
};


const PasswordReset = async (email) => {
    return new Promise((resolve, reject) => {
        // Perform the password reset email sending operation
        sendPasswordResetEmail(auth, email)
            .then(() => {
                resolve('Success'); // Pass back success message
            })
            .catch((error) => {
                reject(error); // Pass back the error message
            });
    });
}

const addUserPhoneNumber = async (phoneNumber) => {
    await addDoc(collection(db, "PhoneNumbers"), {
        uid: auth.currentUser.uid,
        phoneNumber: phoneNumber
    });

}


auth.onAuthStateChanged(async (user) => {
    let userSessionTimeout = null;
    if (user === null && userSessionTimeout) {
        clearTimeout(userSessionTimeout);
        userSessionTimeout = null;
    }
    else if (user) {
        const onlineUsersRef = collection(db, "onlineUsers");
        const userRef = doc(onlineUsersRef, user.uid);
        const q = query(collection(db, "users"), where("uid", "==", user.uid));
        const querySnapshot = await getDocs(q);
        var userName;
        querySnapshot.forEach((doc) => {
            userName = doc.data().name;
        });
        setDoc(userRef, { online: true, name: userName }, { merge: true });
        window.addEventListener("beforeunload", () => {
            setDoc(userRef, { online: false }, { merge: true });
        });
        user.getIdTokenResult().then((idTokenResult) => {
            const authTime = idTokenResult.claims.auth_time * 1000;
            const sessionDurationInMilliseconds = 60 * 60 * 10000; // 60 min
            const expirationInMilliseconds = sessionDurationInMilliseconds - (Date.now() - authTime);
            userSessionTimeout = setTimeout(() => auth.signOut(), expirationInMilliseconds);
        });
    }


});



export {
    app,
    logInWithEmailAndPassword,
    registerWithEmailAndPassword,
    PasswordReset,
    auth,
    db,
    database,
    serverTimestamp,
    ref,
    onValue,
    onDisconnect,
    set,
    doc,
    setDoc,
    addUserPhoneNumber,
    storage
};