import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { collection, getDocs, setDoc, doc, deleteDoc } from 'firebase/firestore';
import { db } from './firebase';

const TableEditor = forwardRef((props, ref) => {
    const refContainer = useRef(null);
    const [selectedCell, setSelectedCell] = useState(null);
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [rowData, setRowData] = useState([]);

    const handleSelectionChanged = (event) => {
        setSelectedCell(event.value);
    };
    useEffect(() => {
        const getData = async (dateValue) => {
            await getDocs(collection(db, "dropDetails"))
                .then((querySnapshot) => {
                    const newData = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
                    newData.sort((a, b) => a.id - b.id);
                    setRowData(newData);
                });
        };
        getData();

    }, [])

    const columnDefs = useMemo(() => [
        { headerName: '', field: 'col1', width: 60 },
        { headerName: '', field: 'col2', width: 60 },
        { headerName: '', field: 'col3', width: 60 },
        { headerName: '', field: 'col4', width: 60 },
        { headerName: '', field: 'col5', width: 60 },
        { headerName: '', field: 'col6', width: 60 },
        { headerName: '', field: 'col7', width: 60 },
    ], []);



    useEffect(() => {
        if (gridApi && gridColumnApi) {
            gridApi.setColumnDefs(columnDefs);
        }
    }, [gridApi, gridColumnApi, columnDefs]);

    const onGridReady = (params) => {
        setGridApi(params.api);
        params.api.setRowData(rowData);
        setGridColumnApi(params.columnApi);
    };
    useImperativeHandle(ref, () => {
        return {
            getValue() {
                return selectedCell;
            },
        };
    });

    useEffect(() => {
        if (selectedCell !== null) {
            props.stopEditing();
        }
    }, [props, selectedCell]);

    return (
        <div
            className="ag-theme-alpine" style={{ height: 600, width: 500 }}
            ref={refContainer}
            tabIndex={1}
        >
            <AgGridReact
                onCellClicked={handleSelectionChanged}
                rowData={rowData}
                onGridReady={onGridReady}
                columnDefs={columnDefs}
                headerHeight={0}  >
            </AgGridReact>
        </div>
    );
});

export default TableEditor;
