import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Checkbox, FormControlLabel, IconButton } from '@mui/material';
import { blue } from '@mui/material/colors';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import {
    getFirestore,
    query,
    getDocs,
    collection,
    where,
    doc,
    setDoc,
    addDoc

} from "firebase/firestore";
import { auth } from './firebase';
import { db } from './firebase';
import { useAuthState } from "react-firebase-hooks/auth";
import { getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions';
import PricingPackage from './PricingPackage';
import { CircularProgress } from '@mui/material';

const jsonData = [
    {
        id: 'ODDS',
        name: 'Odds',
        amount: 300,
    },
    {
        id: 'SELECTION',
        name: 'Selection',
        amount: 400,

    },
    {
        id: 'PADDOCK_OPINION',
        name: 'Paddock Opinion',
        amount: 500,
    }
];


const PaymentDialogPhonePe = ({ open, onClose, index }) => {
    const navigate = useNavigate();
    const [user, loading, error] = useAuthState(auth);
    useEffect(() => {
        if (loading) <CircularProgress />;
        if (!user) return navigate("/login");
        // fetchUserName();
    }, [user, loading]);
    // const [checkedItems, setCheckedItems] = useState({});
    // const [totalPrice, setTotalPrice] = useState(0);

    // useEffect(() => {
    //     calculateTotalPrice();
    // }, [checkedItems]); // Calculate total price whenever checkedItems changes

    // const handleCheckboxChange = (itemId) => (event) => {
    //     setCheckedItems({ ...checkedItems, [itemId]: event.target.checked });
    // };

    // const calculateTotalPrice = () => {
    //     let total = 0;
    //     jsonData.forEach((item) => {
    //         if (checkedItems[item.id]) {
    //             total += item.amount;
    //         }
    //     });
    //     setTotalPrice(total);
    // };

    const handlePayment = async (data) => {
        const functions = getFunctions();
        // connectFunctionsEmulator(functions, 'localhost', 5001);
        const initiatePayment = httpsCallable(functions, 'initiatePayment');

        const amount = parseInt(data);
        initiatePayment({ amount: amount, uid: user?.uid })
            .then((result) => {
                window.location.href = result.data.data.instrumentResponse.redirectInfo.url;
            })
            .catch((error) => {
                console.log(error)
            });
    };

    // const areAllItemsUnchecked = () => {
    //     return Object.values(checkedItems).every(checked => !checked);
    // };


    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle style={{ backgroundColor: blue[500], color: 'white', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                Choose Items
                <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                {/* {jsonData.map((item) => (
                    <div key={item.id} style={{ display: 'flex', alignItems: 'center' }}>
                        <FormControlLabel
                            control={<Checkbox checked={checkedItems[item.id] || false} onChange={handleCheckboxChange(item.id)} />}
                            label={item.name}
                            style={{ color: blue[500] }}
                        />
                    </div>
                ))} */}
                {/* <PaymentTable jsonData={jsonData} checkedItems={checkedItems} handleCheckboxChange={handleCheckboxChange} /> */}
                <PricingPackage handlePayment={handlePayment} index={index} />
            </DialogContent>
            {/* <DialogActions style={{ flexDirection: 'column', alignItems: 'stretch' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
                    <span style={{ fontWeight: 'bold' }}>Total Price: {totalPrice} {jsonData[0]?.currency}</span>
                </div>
                <Button onClick={handlePayment} color="primary" variant="contained" fullWidth disabled={totalPrice === 0 || areAllItemsUnchecked()}>
                    Pay Now
                </Button>
            </DialogActions> */}
        </Dialog>
    );
};

export default PaymentDialogPhonePe;
