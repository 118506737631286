import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
} from '@mui/material';
import customParseFormat from "dayjs/plugin/customParseFormat";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import { db } from './firebase';
import { collection, getDocs, setDoc, doc } from 'firebase/firestore';
import AlertComponent from './AlertComponent';

const MyDialogEdit = ({ open, onClose, cellValues }) => {
    const [currDate, setCurrDate] = React.useState(dayjs(cellValues?.currDate, 'DD/MM/YYYY'));
    const [selectedOption1, setSelectedOption1] = useState('');
    const [selectedOption2, setSelectedOption2] = useState(cellValues?.venue);
    const [collectionNames, setCollectionNames] = useState([]);
    const [oddsDateValue, setOddsDateValue] = useState(cellValues?.oddsDateValue);
    const [alertStatus, setAlertStatus] = useState();
    const [docId, setDocId] = useState(cellValues?.id);
    const tabLabels = ['Bangalore', 'Mumbai', 'Pune', 'Mysore', 'Delhi', 'Kolkata', 'Chennai', 'Hyderabad', 'Ooty'];


    const handleSave = async () => {
        const querySnapshot = await getDocs(collection(db, oddsDateValue + "Race1myData"));
        const data = querySnapshot.docs.map((doc) => doc.data());
        if (data.length > 0) {
            const firebaseHomeTableData = "oddsDetails";
            const oddsRef = doc(db, firebaseHomeTableData, docId);
            await setDoc(oddsRef, { currDate: dayjs(currDate).format('DD/MM/YYYY'), venue: selectedOption2, oddsDateValue: oddsDateValue });
            setAlertStatus(true)
            onClose();
        } else {
            setAlertStatus(false);
        }

    };

    const handleCancel = () => {
        onClose();
    };

    const handleOption1Change = (event) => {
        setSelectedOption1(event.target.value);
    };

    const handleOption2Change = (event) => {
        setSelectedOption2(event.target.value);
    };

    const handleOdds = (newValue) => {
        setCurrDate(newValue);
        setOddsDateValue(dayjs(newValue).format('DDMMYYYY'));
    }

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Edit Odds Details</DialogTitle>
            <DialogContent>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']}>
                        <DatePicker
                            label="Odds Name"
                            value={currDate}
                            format="DD/MM/YYYY"
                            onChange={(newValue) => handleOdds(newValue)}

                        />
                    </DemoContainer>
                </LocalizationProvider>
                <br />
                <FormControl style={{ width: '100%' }}>
                    <InputLabel>Venue</InputLabel>
                    <Select value={selectedOption2} onChange={handleOption2Change}>
                        {tabLabels.map((label) => (
                            <MenuItem key={label} value={label}>
                                {label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <br />
                <br />
                <TextField
                    label=""
                    style={{ width: '100%' }}
                    value={oddsDateValue || ''}
                    disabled
                />
                <br />
                {alertStatus !== undefined ? <AlertComponent isSuccess={alertStatus} /> : ''}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSave} color="primary">
                    Update
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default MyDialogEdit;
