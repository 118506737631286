import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Alert, Button, Grid, Snackbar, TextareaAutosize } from "@mui/material";
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import { useState, useRef } from 'react';
import React, { useEffect } from 'react';
import ExcelManager from './ExcelManager';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import dayjs from 'dayjs';
import SaveIcon from '@mui/icons-material/Save';
import { collection, getDocs, setDoc, doc, deleteDoc } from 'firebase/firestore';
import { db } from './firebase';

const BetManagement = () => {
    const [textValue, setTextValue] = useState('');
    const gridRef = useRef(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [rowData, setRowData] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [severity, setSeverity] = React.useState("success");
    const [message, setMessage] = React.useState();

    const handleTextChange = (event) => {
        setTextValue(event.target.value);
    };

    useEffect(() => {
        const getData = async () => {
            let firebaseDataExists = 0;
            await getDocs(collection(db, "betDetails"))
                .then((querySnapshot) => {
                    const newData = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
                    newData.sort((a, b) => a.id - b.id);
                    setRowData(newData);
                    firebaseDataExists = newData.length;
                });
            if (firebaseDataExists) {
                setOpen(true);
                setSeverity("success")
                setMessage("Data Loaded Successfully")
            } else {
                setOpen(true);
                setSeverity("warning")
                setMessage("No Data Found")
            }
        };
        getData();

    }, [])

    const columnDefs = [
        { headerName: 'Date', field: 'raceDate', editable: true, filter: true, },
        { headerName: 'Venue', field: 'venue', editable: true, filter: true, },
        { headerName: 'Race No', field: 'raceNumber', editable: true, filter: true, },
        { headerName: 'Horse Name', field: 'horseName', editable: true, filter: true, },
        { headerName: 'Win', field: 'win', editable: true, filter: true, },
        { headerName: 'Place', field: 'place', editable: true, filter: true, },
        { headerName: 'Odds', field: 'odds', editable: true, filter: true, },
        { headerName: 'Result', field: 'result', editable: true, filter: true, },
        { headerName: 'Points Earned', field: 'pointsEarned', editable: true, filter: true, },
        { headerName: 'Final Points', field: 'finalPoints', editable: true, filter: true, },
        { headerName: 'Growth %', field: 'growth', editable: true, filter: true, },
    ]
    const handleAddRow = () => {

        const rows = textValue.split('\n').map((row) => {
            const [raceDate, venue, raceNumber, horseName, win, place, odds, result, pointsEarned, finalPoints, growth] = row.split('\t');

            return {
                raceDate: raceDate,
                venue: venue,
                raceNumber: raceNumber,
                horseName: horseName,
                win: win,
                place: place,
                odds: odds,
                result: result,
                pointsEarned: pointsEarned,
                finalPoints: finalPoints,
                growth: growth
            };
        }).filter((row) => Object.values(row).every(value => value !== undefined));



        if (!rows.length) {
            setOpen(true);
            setSeverity("error")
            setMessage("No data found please paste some data in textbox")
            return
        }
        const newRowData = [...rowData, ...rows];
        setRowData(newRowData);
        setTextValue('');
        setOpen(true);
        setSeverity("success")
        setMessage("Excel data has been imported to table")
        setDialogOpen(false);

    };

    const onGridReady = (params) => {
        gridRef.current = params.api;
        gridRef.current.sizeColumnsToFit();
    };

    const handleOpen = () => {
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    const clearAllRowData = async () => {
        if (gridRef.current) {
            gridRef.current.setRowData([]);
        }
    };

    const handleSave = async () => {
        rowData.forEach(async (obj, index) => {
            const firebaseDataName = "betDetails";
            const horseRef = doc(collection(db, firebaseDataName), String(index));
            await setDoc(horseRef, obj);
        });
        setOpen(true);
        setMessage("Data Saved Successfully in Database");
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);

    };


    return (
        <>
            <Grid container direction="column" spacing={2}>
                <Dialog open={dialogOpen} onClose={handleCloseDialog}>
                    <DialogTitle>Add Excel Data</DialogTitle>
                    <DialogContent>
                        <ExcelManager
                            handleAddRow={handleAddRow}
                            textValue={textValue}
                            handleTextChange={handleTextChange} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialog}>Close</Button>
                    </DialogActions>
                </Dialog>
                <Grid item>
                    <Button
                        variant="contained"
                        size="small"
                        onClick={clearAllRowData}
                        style={{ backgroundColor: 'red', color: 'white', marginRight: '10px' }}
                        startIcon={<DeleteForeverIcon />}
                    >
                        Clear All Row Data
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleSave}
                        startIcon={<SaveIcon />}
                        size="small"
                        style={{ backgroundColor: 'blue', color: 'white', marginRight: '10px' }}
                    >
                        Save
                    </Button>
                    <Button
                        size="small"
                        variant="contained"
                        onClick={handleOpen}>
                        Add Excel Data
                    </Button>

                </Grid>
                <Grid item>
                    <div className="ag-theme-alpine" style={{ height: 'auto', width: 'auto' }}>
                        <AgGridReact
                            onGridReady={onGridReady}
                            rowData={rowData}
                            columnDefs={columnDefs}
                            animateRows={true}
                            // getRowNodeId={getRowNodeId}
                            domLayout="autoHeight"
                        // onCellValueChanged={handleCellValueChanged}
                        />
                    </div>
                </Grid>
            </Grid>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        </>

    )


}


export default BetManagement;