import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';

const circularCardStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 20,
  height: 20,
  // borderRadius: '50%',
 
};

const textStyles = {
  textAlign: 'center',
};

const CircularCardOdds = ({ text, cardColor, textColor, borderColor }) => {
  const cardStyle = {
    ...circularCardStyle,
    backgroundColor: cardColor || '#2196F3', // Default card color: blue
    color: textColor || '#fff', // Default text color: white
    boxShadow: `0px 4px ${12 || 8}px rgba(0, 0, 0, 0.25)`,
    border: borderColor ? `1px solid ${borderColor}` : 'none'
  };

  return (
    <Card style={cardStyle}>
      <CardContent style={{ padding: 0 }}>
        {/* <Typography variant="p" style={textStyles}> */}
          {text}
        {/* </Typography> */}
      </CardContent>
    </Card>
  );
};

export default CircularCardOdds;
