
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Button } from '@mui/material';
import { TextareaAutosize } from '@mui/material';

const ExcelManager = ({ handleAddRow, textValue, handleTextChange }) => {
    return (
        <div style={{ height: '550px', width: '500px', }}>
            <Button
                variant="contained"
                style={{ width: '500px' }}
                onClick={handleAddRow}
                startIcon={<CloudUploadIcon />}>
                Add Excel Data to Table
            </Button>
            <TextareaAutosize
                placeholder="Copy & Paste Data from Excel Here"
                style={{ width: '100%', height: '100%' }}
                value={textValue}
                onChange={handleTextChange} />
        </div>
    )
}

export default ExcelManager;