import React from 'react';

export default (params) => {
    return <span className="my-renderer">
        <b>
            {params.value}
        </b>
        {/* <img src="https://media1.giphy.com/media/v1.Y2lkPTc5MGI3NjExMjJmZTE4OThhZDMwZDU2Mzg1ZDFhZTc2ZGVjYTBmMTg5NWJkMzZmZiZlcD12MV9pbnRlcm5hbF9naWZzX2dpZklkJmN0PXM/9PekRRQ0WlrRVwynAk/giphy.gif" className="my-spinner" /> */}
    </span>;
};
