import React, { useState, useMemo, useRef, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { collection, query, getDocs, setDoc, doc, onSnapshot, updateDoc, getDoc, deleteDoc } from "firebase/firestore";
import { db } from './firebase';
import './styles.css';
import { ImageCellRenderer, ImageCellRendererScore, ImageCellRendererPaddockOpinion,ImageCellRendererHorseName } from './utility';

function OddsHorsePaddock({ raceNumber, venue, oddsDateValue }) {
    const gridApiRef = useRef(null);
    const [rowDataFirstFav, setRowDataFirstFav] = useState([]);
    const [rowDataSecondFav, setRowDataSecondFav] = useState([]);
    const [rowData, setRowData] = useState([]);
    const [horseData, setHorseData] = useState([]);
    const [gridApi, setGridApi] = useState(null);
    const containerStyle = useMemo(() => ({ height: '100%' }), []);
    const gridStyle = useMemo(() => ({ height: '100%', width: 'auto' }), []);
    const [columnDefs, setColumnDefs] = useState([
        // {
        //     id: 0,
        //     headerName: 'Favourite',
        //     field: 'favourite',
        //     maxWidth: 100,
        //     cellStyle: params => ({ textAlign: 'center' }),
        //     cellClassRules: {
        //         'custom-border-class': (params) => params.column.getColId() === 'favourite', // Apply border class for column 1
        //         'my-header-class_paddock': (params) => params.column.getColId() === 'favourite'
        //     }
        // },
        {
            maxWidth: 50,
            id: 1,
            headerName: 'Horse #',
            cellStyle: params => ({ textAlign: 'center' }),
            field: 'horseNumber',
            cellClassRules: {
                'custom-border-class': (params) => params.column.getColId() === 'horseNumber', // Apply border class for column 1
            },
            
        },
        {
            maxWidth: 100,
            id: 2,
            cellStyle: params => ({ textAlign: 'center' }),
            headerName: 'Horse Name ',
            field: 'horseName',
            cellClassRules: {
                'custom-border-class': (params) => params.column.getColId() === 'horseName', // Apply border class for column 1
                'my-header-class': (params) => params.column.getColId() === 'horseName'
            },
            cellRendererFramework: ImageCellRendererHorseName,

        },
        {
            maxWidth: 120,
            id: 3,
            cellStyle: params => ({ textAlign: 'center' }),
            headerName: 'Paddock Opinion',
            field: 'paddockOpinion',
            cellClassRules: {
                'custom-border-class': (params) => params.column.getColId() === 'paddockOpinion', // Apply border class for column 1
            },
            cellRendererFramework: ImageCellRendererPaddockOpinion,

        }
    ]);

    const onGridReady = (params) => {
        setGridApi(params.api);
        gridApiRef.current.api.sizeColumnsToFit();
    };

    useEffect(() => {
        const getHorses = async () => {
            try {
                const horseDatafb = [];
                const q = query(collection(db, oddsDateValue + "horseDetails"));
                const doc = await getDocs(q);
                const data = doc.docs.map((doc) => (
                    horseDatafb.push(doc.data())
                ));
                setHorseData(horseDatafb);
            } catch (err) {
                console.error(err);
                alert("An error occured while fetching user data");
            }
        }
        getHorses();
    }, [raceNumber])

    useEffect(() => {
        const filteredHorseData = horseData.filter(obj => {
            return obj.raceNumber === raceNumber && (obj.hasOwnProperty('paddockOpinion'))
        });

        const filteredDataFirstFav = filteredHorseData.find(item => item.favourite === '1st favourite');
        const filteredDataSecondFav = filteredHorseData.find(item => item.favourite === '2nd favourite');
        setRowDataFirstFav([filteredDataFirstFav]);
        setRowDataSecondFav([filteredDataSecondFav]);
        setRowData(filteredHorseData);
    }, [horseData])



    return (
        <div className="ag-theme-alpine" style={{ height: 800}}>
            <div style={gridStyle} className="ag-theme-alpine">
                <AgGridReact
                    className="ag-theme-alpine"
                    ref={gridApiRef}
                    rowData={rowData}
                    columnDefs={columnDefs}
                    suppressRowTransform={true}
                    onGridReady={onGridReady}
                    // getRowStyle={getRowStyle}
                    rowHeight={50}
                    headerHeight={20}
                ></AgGridReact>
            </div>
        </div >
    )
}


export default OddsHorsePaddock;