
export const homeObj1About = {
    id: 'about',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'About Us',
    headline: 'Are you a fan of horse racing seeking for the best place to ignite your fire? Well, Congratulations!!! You\'ve found it! ',
    description: 'A single source for all things pertaining to the exciting world of horse racing. Get ready to embark on a journey towards becoming a master at winning. Whether you\'re a punter or just a wee pony, we\'ve got something for everyone. If you\'re relatively new to the world of horse racing, it will make sense to start by getting a firm grasp on how everything moves. Unlike traditional methods, Ashwa Vaarte is always working towards giving you the latest & the most accurate information on the Indian Horse Racing sector, including the Real Time information about the Race Locations, Handicapping Points, Selection Info, Odds & other elements that have positive effects on the outcome of the race. After consulting with numerous expert professionals and punters, our team of researchers & specialists have concluded that there are 3 Key Factors that has to be considered carefully in making every decision in the field of Horse Racing. As a result, our team has developed this special platform after conducting in-depth market research and analysis. It gives you access to all 3 important factors. This is done so that you can assess market trends, prevent choosing the erroneous bets, and always be a winner.The 3 Key Factors are 1. Handicapping2. Paddock Looks & Race Day Fitness3. Market Trends (the fluctuations in the odds)Sign Up to know more about the above 3 Key Factors which will assist you in making the right decision and will also make sure that you become an expert with winning as a habit.',
    buttonLabel: 'Get Started',
    imgStart: false,
    img: require('../../Images/OT.svg').default,
    alt: 'Car',
    dark: true,
    primary: true,
    darkText: false
}
export const homeObj2 = {
    id: 'discover',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Unlimited Access',
    headline: 'Learn how to use Ashwa Vaarte using the instructional Videos',
    description: 'We have you covered no matter where you are located. All you need is internet connection and a phone.',
    buttonLabel: 'Learn More',
    imgStart: true,
    img: require('../../Images/3.svg').default,
    alt: 'Piggybank',
    dark: false,
    primary: false,
    darkText: true
}
export const homeObj3 = {
    id: 'signup',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Join our Team',
    headline: 'Creating and account is extremely easy',
    description: 'Get everything set up and readyin under 10 minutes. All you need to do is add your information and you are good to go',
    buttonLabel: 'Start Now',
    imgStart: false,
    img: require('../../Images/Account2.svg').default,
    alt: 'Paper',
    dark: false,
    primary: false,
    darkText: true
}