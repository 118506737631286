import { AgGridReact } from 'ag-grid-react';
import React, { useState, useEffect, useMemo, useRef, useContext, useCallback } from 'react';
import './styles.css';
import { collection, query, getDocs, setDoc, doc, onSnapshot, updateDoc, getDoc, deleteDoc } from "firebase/firestore";
import { db } from './firebase';
import TableRenderer from './TableRenderer';
import TableEditor from './TableEditor';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import './styles.css';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import MyContext from './MyContext';
import { useNavigate } from "react-router-dom";
import { getCellStyle, ImageCellRendererHorseName } from './utility';
import { Grid, Button, Typography, TextField, InputLabel, MenuItem, Select } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import convertStringToRace from './utilities';
import { getRowStyle } from './utility';
import { Tab, Tabs } from '@mui/material';
import CustomDropdownComponent from './CustomDropdownComponent';
import { ImageCellRenderer, ImageCellRendererScore, ImageCellRendererPaddockOpinion } from './utility';
import { auth } from './firebase';
import { useAuthState } from "react-firebase-hooks/auth";

function getMaxFilledColumn(rowData) {
    let maxColumnIndex = -1;
    rowData.forEach(row => {
        Object.keys(row).forEach(column => {
            if (column.startsWith('col')) {
                const columnIndex = parseInt(column.substr(3));
                if (columnIndex > maxColumnIndex && row[column] !== null && row[column] !== undefined && row[column] !== '') {
                    maxColumnIndex = columnIndex;
                }
            }
        });
    });

    return maxColumnIndex;
}

function OddsNew({ raceNumber, venue, oddsDateValue }) {
    const [user, loading, error] = useAuthState(auth);
    const [admin, setAdmin] = useState(false);
    const [pageName, setPageName] = useState();
    const [gridApi, setGridApi] = useState(null);
    const [rowData, setRowData] = useState([]);
    const [columnDefs, setColumnDefs] = useState([]);
    const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
    const gridApiRef = useRef(null);
    const [horseStatus, sethorseStatus] = useState('');
    const [horseStatusText, sethorseStatusText] = useState('');
    const [horseData, setHorseData] = useState([]);
    const [horseOptions, setHorseOptions] = useState([])

    const navigate = useNavigate();
    const cellCount = 50;
    const generatedCode = [
        {
            maxWidth: 100,
            id: 0,
            headerName: 'Name',
            field: 'horseName',
            cellClassRules: {
                'custom-border-class': (params) => params.column.getColId() === 'horseName', // Apply border class for column 1
            },
            rowSpan: (params) => {
                const rowIndex = params.node.rowIndex;
                return rowIndex % 2 === 0 ? 2 : 1;
            },
            cellStyle: (params) => {
                const backgroundColor = params.data.horseColor;
                return { backgroundColor };
            },
            cellRendererFramework: ImageCellRendererHorseName,
        }, {
            maxWidth: 60,
            id: 1,
            headerName: '',
            field: 'horseNumber',
            cellClassRules: {
                'custom-border-class': (params) => params.column.getColId() === 'horseNumber', // Apply border class for column 1
            },
            rowSpan: (params) => {
                const rowIndex = params.node.rowIndex;
                return rowIndex % 2 === 0 ? 2 : 1;
            },
            cellStyle: (params) => {
                const backgroundColor = params.data.horseColor;
                return { backgroundColor };
            },
            cellRendererFramework: ImageCellRenderer,

        },
        {
            maxWidth: 30,
            hide: false,
            id: 2,
            field: 'MO',
            editable: true,
            cellRenderer: TableRenderer,
            cellEditor: TableEditor,
            cellEditorPopup: true,
            suppressNavigable: true,
            suppressMovable: true,
            // cellStyle: params => getCellStyle(params, gridApiRef, 'pos')
        },
        {
            maxWidth: 30,
            hide: false,
            id: 3,
            field: 'NO',
            editable: true,
            cellRenderer: TableRenderer,
            cellEditor: TableEditor,
            cellEditorPopup: true,
            suppressNavigable: true,
            suppressMovable: true,
            // cellStyle: params => getCellStyle(params, gridApiRef, 'pos')
        },
        {
            maxWidth: 30,
            hide: false,
            id: 4,
            field: 'OO',
            editable: true,
            cellRenderer: TableRenderer,
            cellEditor: TableEditor,
            cellEditorPopup: true,
            suppressNavigable: true,
            suppressMovable: true,
            // cellStyle: params => getCellStyle(params, gridApiRef, 'pos')
        },
        {
            maxWidth: 20,
            hide: false,
            id: 5,
            field: 'pos',
            cellStyle: params => getCellStyle(params, gridApiRef, 'pos')
        }];

    const ClearButtonRenderer = ({ column, api, node }) => {
        const clearColumn = async () => {
            const colId = column.getColId();
            let newData = [];
            api.forEachNode(node => {
                const updatedData = { ...node.data, [colId]: null };
                node.setData(updatedData);
                newData.push(updatedData);
            });
            api.refreshCells({ force: true });
            setRowData(newData);
        };
        return (<CleaningServicesIcon style={{ width: '25px', height: '15px' }} onClick={clearColumn} />)
    };

    for (let i = 1; i <= cellCount; i++) {
        const field = `col${i}`;
        generatedCode.push({
            id: i + 5,
            headerName: '',
            headerComponentFramework: ClearButtonRenderer,
            maxWidth: 30,
            field: field,
            editable: true,
            cellRenderer: TableRenderer,
            cellEditor: TableEditor,
            cellEditorPopup: true,
            suppressNavigable: true,
            suppressMovable: true,
            cellStyle: params => getCellStyle(params, gridApiRef, field)
        });
    }


    const predefinedValues = ['HORSES ARE ARRIVING',
        'HORSES ARE PARADING IN THE PADDOCK',
        'JOCKEYS MOUNTING',
        'HORSES ARE LEAVING THE PADDOCK',
        'HORSES REACHED THE STARTING GATE',
        'JOCKEYS MOUNTING',
        'LOADING STARTED',
        'RACE STARTED',
        'UNOFFICIAL RESULT',
        'FINAL RESULT',];


    useEffect(() => {
        setColumnDefs(generatedCode);
        const getHorses = async () => {
            try {
                const horseDatafb = [];
                const q = query(collection(db, oddsDateValue + "horseDetails"));
                const doc = await getDocs(q);
                const data = doc.docs.map((doc) => (
                    horseDatafb.push(doc.data())
                ));
                setHorseData(horseDatafb);
            } catch (err) {
                console.error(err);
                alert("An error occured while fetching user data");
            }
        }
        getHorses();
    }, [raceNumber])


    useEffect(() => {
        var emails = ["anilkavali601@gmail.com"]
        if (emails.includes(user.email)) {
            setAdmin(true);
        }
    }, [user]);





    useEffect(() => {
        createRowData();
    }, [horseData])

    useEffect(() => {
        const q = query(collection(db, pageName + "myData"));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const rows = [];
            querySnapshot.forEach((doc) => {
                rows.push(doc.data());
            });
            if (rows.length > 0) {
                rows.sort((a, b) => a.id - b.id);
                setRowData(rows);
            }
        });

        const horseStatusFb = query(collection(db, pageName + "Status"));
        const unsubscribeHorseStatus = onSnapshot(horseStatusFb, (querySnapshot) => {
            const horseStatusFb = [];
            querySnapshot.forEach((doc) => {
                horseStatusFb.push(doc.data());
            });
            sethorseStatus(horseStatusFb[0]?.horseStatusData);
        });

        const textInputValueFb = query(collection(db, pageName + "StatusText"));
        const unsubscribeStatusText = onSnapshot(textInputValueFb, (querySnapshot) => {
            const horseStatusTextFb = [];
            querySnapshot.forEach((doc) => {
                horseStatusTextFb.push(doc.data());
            });
            sethorseStatusText(horseStatusTextFb[0]?.text);
        });

    }, [pageName])

    const defaultColDef = useMemo(() => {
        return {
            width: 170
        };
    }, []);

    const createRowData = () => {
        const newData = []
        const filteredData = horseData.filter(item => item.raceNumber === parseInt(raceNumber));
        const sortedData = filteredData.sort((a, b) => a.horseNumber - b.horseNumber);
        sortedData.map((item, index) => {
            let horseNumber = `H${item.horseNumber}`
            newData.push({
                id: index * 2,
                horseNumber: horseNumber,
                horseColor: `${item.horseColor}`,
                pos: 'W',
                horseName: `${item.horseName}`
            });
            newData.push({
                id: index * 2 + 1,
                horseNumber: `${horseNumber}.5`,
                horseColor: `${item.horseColor}`,
                pos: 'P',
                parent: horseNumber
            });
        })
        setRowData(newData);
        if (!oddsDateValue) return navigate("/home");
        setPageName(oddsDateValue + "Race" + raceNumber);
        setHorseOptions(horseData.filter(item => item.raceNumber === parseInt(raceNumber)).map(item => `H` + item.horseNumber));
    };

    const onGridReady = (params) => {
        setGridApi(params.api);
    };

    const handleCellEditingStopped = async (event) => {
        const { data, colDef, newValue, rowIndex, column, api, node } = event;
        api.setFocusedCell(rowIndex, column.colId);
        api.refreshCells({
            rowNodes: [node],
        });
        updateFirebase(rowData);
    };

    const updateFirebase = async (rowData) => {
        rowData.forEach(async (obj, index) => {
            const firebaseDataName = pageName + "myData";
            const newCityRef = doc(collection(db, firebaseDataName), String(index));
            await setDoc(newCityRef, obj);
        });
    };

    const updateFirebaseFinalJudgeMent = async (updatedRowData) => {
        updatedRowData.forEach(async (obj, index) => {
            const firebaseDataName = pageName + "finalData";
            const newCityRef = doc(collection(db, firebaseDataName), String(index));
            await setDoc(newCityRef, obj);
        });
    };

    const cloneColumn = () => {
        const maxFilledColumn = getMaxFilledColumn(rowData);
        let newData = [];
        gridApi.forEachNode(node => {
            const updatedData = { ...node.data, [`col${maxFilledColumn + 1}`]: node.data[`col${maxFilledColumn}`] !== undefined ? node.data[`col${maxFilledColumn}`] : '' };
            node.setData(updatedData);
            newData.push(updatedData);
        });
        gridApi.refreshCells({ force: true });
        setRowData(newData);
        updateFirebase(newData);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'F2') {
            cloneColumn();
        }
    };

    const updateHorseData = async (event) => {
        const firebaseDataName = pageName + "Status";
        const headerRef = doc(db, firebaseDataName, firebaseDataName);
        await setDoc(headerRef, { horseStatusData: event.target.value }, { merge: true });
    }

    const handleInputChange = (event) => {
        sethorseStatusText(event.target.value);
    };

    const handleCreate = async () => {
        const firebaseDataName = pageName + "StatusText";
        const headerRef = doc(db, firebaseDataName, firebaseDataName);
        await setDoc(headerRef, { text: horseStatusText }, { merge: true });
    };

    const handleDeleteData = async () => {
        await getDocs(collection(db, pageName + "myData"))
            .then((querySnapshot) => {
                querySnapshot.docs.map((docFb) => (
                    deleteDoc(doc(db, pageName + "myData", docFb.id))
                ));
            });
    }

    return (
        <>
            <div
                onKeyDown={handleKeyDown}
                tabIndex={0}
                style={{ marginTop: '20px' }}
            >
                <Grid container spacing={2} justify="center" alignItems="center" style={{ "border": "1px solid black", marginBottom: "25px" }}>
                    <Grid item >
                        <Typography style={{ textAlign: 'center' }}>
                            {venue} {convertStringToRace(pageName)}
                        </Typography>
                    </Grid>

                    <Grid item>
                        <Button size="small" variant="contained" startIcon={<AddIcon />}
                            onClick={cloneColumn}
                            style={{ marginRight: '8px' }}>
                            Clone Column
                        </Button>
                    </Grid>

                    <Grid item>
                        <InputLabel id="dropdown-label" style={{ marginRight: '8px', alignSelf: 'center' }}>
                            Race Status
                        </InputLabel>
                    </Grid>

                    <Grid item>
                        <Select
                            labelId="dropdown-label"
                            value={horseStatus || ''}
                            onChange={updateHorseData}
                            style={{ height: 30 }}
                        >
                            {predefinedValues.map((value, index) => (
                                <MenuItem key={index} value={value} style={{ fontWeight: 'bold' }}>
                                    {value}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>

                    <Grid item>
                        <TextField
                            size="small"
                            variant="outlined"
                            style={{ height: 40 }}
                            value={horseStatusText}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color="primary"
                            onClick={handleCreate}
                            size="small">
                            Save
                        </Button>
                    </Grid>
                    {admin ? <Grid item>
                        <Button variant="contained" color="error"
                            onClick={handleDeleteData}
                            size="small">
                            Delete Data
                        </Button>
                    </Grid> : ''
                    }

                </Grid>
                <div className="ag-theme-alpine" style={{ height: 800, width: '100%' }}>
                    <AgGridReact
                        ref={gridApiRef}
                        rowData={rowData}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        suppressRowTransform={true}
                        onGridReady={onGridReady}
                        getRowStyle={getRowStyle}
                        rowHeight={25}
                        headerHeight={20}
                        onCellEditingStopped={handleCellEditingStopped}
                    />
                </div>
            </div>
        </>
    )
}

export default OddsNew;
