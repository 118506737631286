import * as React from 'react';
import { useState, useEffect } from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs'; // Import dayjs
import { TextField } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { collection, getDocs, query, where, addDoc } from "firebase/firestore";
import { db, app } from './firebase';
import { Container } from '@mui/material';
import { CircularProgress } from '@mui/material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormGroup, FormControlLabel, Checkbox, Autocomplete, Snackbar, Alert } from '@mui/material';

export default function PaymentManagement() {
    const [date, setDate] = useState(dayjs());
    const [gridApi, setGridApi] = useState(null);
    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [open, setOpen] = useState(false);
    const [snackOpen, setSnackOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [selectedOption, setSelectedOption] = useState(null);
    const [checkboxes, setCheckboxes] = useState({
        odds: false,
        selection: false,
        paddock_opinion: false,
    });

    const columns = [{ headerName: "status", field: "status", filter: 'agTextColumnFilter' },
    { headerName: "features", field: "features", width: 300 },
    { headerName: "Email", field: "email" },
    { headerName: "Phone", field: "phone" },
    { headerName: "Amount", field: "amount" }
    ];

    const formatDateForQuery = (date) => {
        return date ? dayjs(date).format('DDMMYYYY') : '';
    };


    useEffect(() => {
        const fetchUserEmails = async () => {
            try {
                // Reference to the 'users' collection
                const usersCollection = collection(db, "users");

                // Get all documents from the 'users' collection
                const userDocs = await getDocs(usersCollection);

                // Array to store user emails
                let userIdEmailMap = [];

                userDocs.forEach(doc => {
                    const userData = doc.data();
                    userIdEmailMap.push({
                        label: userData.email,
                        id: userData.uid
                    })
                });
                setOptions(userIdEmailMap.sort((a, b) => a.label.localeCompare(b.label)));
                return userIdEmailMap;
            } catch (error) {
                console.error("Error fetching user emails:", error);
            }
        };
        fetchUserEmails();
    }, [])

    useEffect(() => {
        if (!date) return;
        const fetchData = async () => {
            setLoading(true);
            // const today = new Date();
            // const transDate = today.toLocaleDateString('en-GB').replace(/\//g, '');
            const formattedDate = formatDateForQuery(date);
            const transactionQuery = query(collection(db, "transactions" + formattedDate));

            try {
                const querySnapshot = await getDocs(transactionQuery);
                let transactionsToday = [];

                for (const doc of querySnapshot.docs) {
                    const transactionData = doc.data();
                    const userQuery = query(collection(db, "users"), where("uid", "==", transactionData.userId));
                    const userSnapshot = await getDocs(userQuery);

                    // Assuming each user ID corresponds to exactly one user
                    if (!userSnapshot.empty) {
                        const userData = userSnapshot.docs[0].data();
                        // Combine transaction data with user data
                        transactionData.amount = transactionData.amount / 100;

                        transactionsToday.push({ ...transactionData, email: userData.email, phone: userData.phoneNumber, });

                    } else {
                        // In case there's no user data, push only the transaction data
                        transactionsToday.push(transactionData);
                    }
                }

                setRowData(transactionsToday); // Uncomment and use if necessary
                setLoading(false);
                const sumOfAmount = transactionsToday
                    .filter(item => item.status === "PAYMENT_SUCCESS")
                    .reduce((sum, item) => sum + parseInt(item.amount), 0);
                setTotal(sumOfAmount);

            } catch (error) {
                setLoading(false);
                console.error("Error fetching documents: ", error);
            }
        };
        fetchData();
    }, [date]);

    useEffect(() => {
        if (gridApi) {
            // Set default filter model
            gridApi.setFilterModel({
                status: { type: 'contains', filter: 'PAYMENT_SUCCESS' },
                // age: { type: 'greaterThan', filter: 25 }
                // Set more default filters as needed
            });
        }
    }, [gridApi]);

    const onGridReady = params => {
        setGridApi(params.api);
    };



    const handleDateChange = (newValue) => {
        setDate(newValue); // Update the state with the Day.js object
    };

    // Function to format the date for display or other uses
    const formatDate = (date) => {
        return date ? dayjs(date).format('DDMMYYYY') : '';
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSnackClose = () => {
        setSnackOpen(false);
    };

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleCheckboxChange = (event) => {
        setCheckboxes({
            ...checkboxes,
            [event.target.name]: event.target.checked,
        });
    };

    const handleSubmit = async () => {
        const featureAccessDate = new Date();
        const filteredKeys = Object.keys(checkboxes).filter(key => checkboxes[key]).map(key => key.toUpperCase());
        const csv = filteredKeys.join(',');

        const transactionRecord = {
            userId: selectedOption?.id,
            amount: parseInt(0),
            status: "PAYMENT_SUCCESS",
            createdDate: new Date(),
            featureAccessDate: featureAccessDate,
            featureAccessYear: featureAccessDate.getFullYear(),
            featureAccessMonth: featureAccessDate.getMonth() + 1, // JavaScript months are 0-11
            featureAccessDay: featureAccessDate.getDate(),
            features: csv,
        };

        const transDate = new Date().toLocaleDateString("en-GB").replace(/\//g, "");
        const docRef = await addDoc(collection(db, "transactions" + transDate), transactionRecord);
        setSnackOpen(true);
        handleClose();
    };

    const handleAutocompleteChange = (event, value) => {
        setSelectedOption(value);
    };

    return (
        <Container maxWidth="lg" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div style={{ marginBottom: '20px', width: '100%' }}>
                    <DatePicker
                        label="Select a Date"
                        value={date}
                        onChange={handleDateChange}
                        renderInput={(params) => <TextField {...params} fullWidth />}
                    />
                    <p>Selected Date: {formatDate(date)}</p>
                    <div>
                        Total : {total}
                    </div>
                </div>
            </LocalizationProvider>
            <Button variant="outlined" onClick={handleOpen}>
                ADD PAID USER
            </Button>
            {loading && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 400 }}>
                    <CircularProgress />
                </div>
            )}

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Select User</DialogTitle>
                <DialogContent>
                    <Autocomplete
                        options={options}
                        sx={{ width: 500 }}
                        getOptionLabel={(option) => option.label}
                        // value={selectedOption.email}
                        onChange={handleAutocompleteChange}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Choose an option"
                                margin="dense"
                                fullWidth
                            />
                        )}
                    />
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checkboxes.odds}
                                    onChange={handleCheckboxChange}
                                    name="odds"
                                />
                            }
                            label="odds"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checkboxes.selection}
                                    onChange={handleCheckboxChange}
                                    name="selection"
                                />
                            }
                            label="selection"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checkboxes.paddock_opinion}
                                    onChange={handleCheckboxChange}
                                    name="paddock_opinion"
                                />
                            }
                            label="paddock_opinion"
                        />
                    </FormGroup>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
            {/* <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
                <AgGridReact
                />
            </div> */}
            {!loading && (
                <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
                    <AgGridReact
                        onGridReady={onGridReady}
                        rowData={rowData}
                        columnDefs={columns}>
                    </AgGridReact>
                </div>
            )}
            <Snackbar
                open={snackOpen}
                autoHideDuration={6000}
                onClose={handleSnackClose}
                message="Paid User Added Successfully!"
            >
                <Alert onClose={handleSnackClose} severity="success" sx={{ width: '100%' }}>
                    Paid User Added Successfully!
                </Alert>
            </Snackbar>
        </Container>
    );
}
