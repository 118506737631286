import React, { useState } from 'react'
import Navbar from '../Components/Navbar/Index'
import Sidebar from '../Components/SideBar/Index'
import Hero from '../Components/Hero/Index'
import InfoSection from '../Components/Info/Index'
import AboutSection from '../Components/About/Index'
import Services from '../Components/Services/Index'
import { homeObj1, homeObj2, homeObj3 } from '../Components/Info/Data'
import { homeObj1About } from '../Components/About/Data'
import Footer from '../Components/Footer/Index'
import Fab from '@mui/material/Fab';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Container, Box, Grid, Typography, Button } from '@mui/material';
import SubscriptionTable from '../Components/SubscriptionTable'
import PersonalGuide from "../Components/PersonalGuide"
const HomePage = () => {

    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    }
    const fabStyle = {
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        backgroundColor: '#007bff', /* Change to your preferred button color */
        color: '#fff', /* Change to your preferred text color */
        borderRadius: '50%',
        width: '50px',
        height: '50px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.3)',
        transition: 'background-color 0.3s',
    };

    const containerStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#0A0A0B', // Light grey background
        padding: '5px',
        // borderRadius: '10px'
    };

    const buttonStyle = {
        color: 'black',
        backgroundColor: '#01BF71',
        flexGrow: 1, // Allows the button to grow and take available space
        margin: '0 5px'
    };

    return (
        <>
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <Navbar toggle={toggle} />
            <Hero />
            {/* <div style={containerStyle}>
                <Button
                    style={buttonStyle}
                    href="/home"
                    to="login"
                >View Odds</Button>
                <Button
                    style={buttonStyle}
                    href="/signup"
                    to="signup"
                >
                    Signup
                </Button>
            </div> */}
            <AboutSection  {...homeObj1About} />
            <InfoSection  {...homeObj2} />
            <Services />
            {/* <InfoSection  {...homeObj3} /> */}
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Box bgcolor="white" p={2}>
                        <Typography align="center">
                            <h1>Daily Subscriptions</h1>
                        </Typography>
                        <SubscriptionTable />
                    </Box>
                </Grid>
                {/* <Grid item xs={12}>
                    <Box bgcolor="lightgreen" p={2}>
                        One Time Offer
                    </Box>
                </Grid> */}
                <Grid item xs={12}>
                    <Box bgcolor="white" p={2}>
                        <Typography align="center">
                            <h1>Personalized Guidance</h1>
                        </Typography>
                        <PersonalGuide />
                    </Box>
                </Grid>
            </Grid>
            <Footer />

            <Fab color="primary" aria-label="add" style={fabStyle} href={"https://api.whatsapp.com/send?phone=+918618995661&text=Hi"}>
                <WhatsAppIcon />
            </Fab>
        </>
    )
}

export default HomePage
