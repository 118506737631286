import { useState, useEffect } from "react";
import { Tab, Tabs, Typography } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import { Box } from '@mui/material'
const TabNavHome = ({ columnDefs, rowData }) => {
    const [activeTab, setActiveTab] = useState(0);
    const tabLabels = ['Bangalore', 'Mumbai', 'Pune', 'Mysore', 'Delhi', 'Kolkata', 'Chennai', 'Hyderabad', 'Ooty'];
    const [filterdRowData, setFilterdRowData] = useState([]);

    useEffect(() => {
        const storedTab = localStorage.getItem('activeTab');
        if (storedTab) {
            setActiveTab(parseInt(storedTab));
        }
    }, []);

    useEffect(() => {
        // Update the filtered data whenever the rowData prop changes
        const filteredData = rowData.filter(
            item => item.venue.toLowerCase() === tabLabels[activeTab].toLowerCase()
        );

        setFilterdRowData(filteredData);
    }, [rowData, activeTab]);

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
        // Add logic to update filtered data based on the selected tab
        // For example, you can filter the rowData based on the selected tab's label
        const filteredData = rowData.filter(
            item => item.venue.toLowerCase() === tabLabels[newValue].toLowerCase()
        );
        setFilterdRowData(filteredData);
        localStorage.setItem('activeTab', newValue.toString());
    };

    return (
        <Box sx={{ bgcolor: '#1976D2',color:'white'}}>
            <Tabs
                value={activeTab}
                onChange={handleTabChange}
                variant="scrollable"
                allowScrollButtonsMobile
                indicatorColor="secondary"
                scrollButtons="auto"
                textColor="inherit"
            >
                {tabLabels.map((label, index) => (
                    <Tab label={label} key={index} />
                ))}
            </Tabs>
            <div className="ag-theme-alpine" style={{ height: '500px', width: 'auto' }}>
                <AgGridReact 
                rowData={filterdRowData} 
                columnDefs={columnDefs}
                headerHeight="30"
                ></AgGridReact>
            </div>
        </Box>
    );

}


export default TabNavHome;