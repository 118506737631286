import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';

const cardStyleGlobal = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 100,
    height: 35,
    borderRadius: '8px',
    marginTop: '4px'

    // boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
    // width: '80%',
    // height: '80%',
    // borderRadius: '8px',
    // minWidth: 50,
    // maxWidth: 100,

    // margin: '4px',
    // marginTop: '16px',
    // padding: '16px',
};

const titleStyle = {
    fontSize: '15px',
    // marginBottom: '8px',
};

const textStyle = {
    fontSize: '14px',
};

const textStyles = {
    textAlign: 'center',
};

const ElevatedBackgroundCard = ({ text, cardColor, textColor, borderColor }) => {
    const cardStyle = {
        ...cardStyleGlobal,
        backgroundColor: cardColor || '#2196F3', // Default card color: blue
        color: textColor || '#fff', // Default text color: white
        boxShadow: '0 0 50px 1px #48abe0',
        border: borderColor ? `0.5px solid ${borderColor}` : 'none',
        
    };

    return (
        <Card style={cardStyle}>
            <CardContent style={{ padding: 0 }}>
                <Typography variant="p" style={textStyles}>
                    {text}
                </Typography>
            </CardContent>
        </Card>
    );
};

export default ElevatedBackgroundCard;
