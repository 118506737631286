import { Button } from '@mui/material';
import React, { useState } from 'react';
import { BlockPicker } from 'react-color';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';

const ColorComponent = ({ params, onValueChange }) => {
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [color, setColor] = useState('white');

    const handleColorChange = (newColor) => {
        setColor(newColor.hex);
    };
    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    const handleOpen = () => {
        setDialogOpen(true);
    };

    const handleSave = () => {
        setDialogOpen(false);
        onValueChange(color);
    };

    return (
        <div>
            <FormatColorFillIcon onClick={handleOpen} style={{ color: color, width: '100%' }} />
            <Dialog open={dialogOpen} onClose={handleCloseDialog}>
                <DialogTitle>Color</DialogTitle>
                <DialogContent>
                    <BlockPicker
                        color={color}
                        colors={['#00FF00','#FF9900','#FFFF00','#00FFFF','#ff8080']}
                        onChange={handleColorChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Close</Button>
                    <Button onClick={handleSave}>Save</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ColorComponent;