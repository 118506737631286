import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Container, Typography, Link, Box, Divider, Grid, Button } from "@mui/material";
import styled from "@emotion/styled";
import { ref, getDownloadURL } from "firebase/storage";
import { db, storage } from './firebase';
// import SocialAuth from "../components/SocialAuth";
//import Logo from "../components/Logo";
import { motion } from "framer-motion";
import LoginForm from "./LoginForm";
import { collection, getDocs, query, where, onSnapshot } from "firebase/firestore";
import dayjs from 'dayjs';

//////////////////////////////////
const RootStyle = styled("div")({
  background: "rgb(249, 250, 251)",
  height: "80vh",
  display: "grid",
  placeItems: "center",
});

const HeadingStyle = styled(Box)({
  textAlign: "center",
});

const ContentStyle = styled("div")({
  maxWidth: 480,
  padding: 25,
  margin: "auto",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  background: "#fff",
});

let easing = [0.6, -0.05, 0.01, 0.99];
const fadeInUp = {
  initial: {
    y: 60,
    opacity: 0,
    transition: { duration: 0.6, ease: easing },
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.6,
      ease: easing,
    },
  },
};

const Login = ({ setAuth }) => {
  const [updateText, setUpdateText] = useState();

  const divStyleText = {
    fontFamily: 'Arial, sans-serif',
    textAlign: 'center',
    fontSize: '20px',  /* Increased font size for better readability */
    fontWeight: 'bold',
    marginTop: '30px',  /* Increased margin for better spacing */
    color: 'red',  /* A nice blue color for text */
    textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)',  /* Adding a subtle text shadow for depth */
    transition: 'color 0.3s ease-in-out',  /* Smooth color transition on hover or other events */
    cursor: 'pointer'  /* Change cursor on hover to indicate interactivity */
  };

  useEffect(() => {
    const textInputValueFbUpdate = query(collection(db, dayjs(new Date()).format('DDMMYYYY') + "updateText"));
    const unsubscribeStatusTextUpdate = onSnapshot(textInputValueFbUpdate, (querySnapshot) => {
      const linkTextFbUpdate = [];
      querySnapshot.forEach((doc) => {
        linkTextFbUpdate.push(doc.data());
      });
      linkTextFbUpdate.length > 0 ? setUpdateText(linkTextFbUpdate[0]?.text) : setUpdateText('');
    });
  }, []);

  return (
    <RootStyle>
      <div style={divStyleText}>
        Today's News : {updateText}
      </div>
      <Container maxWidth="sm">
        <ContentStyle>
          <HeadingStyle component={motion.div} {...fadeInUp}>
            {/* <Logo /> */}

            <Typography sx={{ color: "text.secondary", mb: 5 }}>
              Login to your account
            </Typography>
            <Button
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              href="/signup"
            >
              Signup
            </Button>
          </HeadingStyle>

          <Box component={motion.div} {...fadeInUp}>
            {/* <SocialAuth /> */}
          </Box>

          <Divider sx={{ my: 3 }} component={motion.div} {...fadeInUp}>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              OR
            </Typography>
          </Divider>

          <LoginForm setAuth={setAuth} />

          <Typography
            component={motion.p}
            {...fadeInUp}
            variant="body2"
            align="center"
            sx={{ mt: 3 }}
          >
            Don’t have an account?{" "}
            <Link variant="subtitle2" component={RouterLink} to="/signup">
              Sign up
            </Link>
          </Typography>

        </ContentStyle>
      </Container>
    </RootStyle>
  );
};

export default Login;