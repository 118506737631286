import React, { useEffect, useState } from 'react'
import { FaBars } from 'react-icons/fa'
import { animateScroll as scroll } from 'react-scroll'
import {
    Nav,
    NavbarContainer,
    NavLogo,
    MobileIcon, NavMenu,
    NavItem,
    NavLinks,
    NavBtn,
    NavBtnLink,
    StyledImage,
    NavLinkRefund
} from './NavElements'
import logo from '../../logo11.png';
const Navbar = ({ toggle }) => {

    const [scrollNav, setScrollNav] = useState(false);

    const changeNav = () => {
        if (window.scrollY >= 80) {
            setScrollNav(true);
        } else {
            setScrollNav(false);
        }
    }
    useEffect(() => {
        window.addEventListener('scroll', changeNav)
    }, [])

    const toggleHome = () => {
        scroll.scrollToTop()
    }


    return (
        <>
            <Nav scrollNav={scrollNav}>
                <NavbarContainer>
                    
                    <NavLogo to="/" onClick={toggleHome}>
                    <StyledImage src={logo}></StyledImage>
                        Ashwa Vaarte
                    </NavLogo>
                    <MobileIcon onClick={toggle}>
                        <FaBars />
                    </MobileIcon>
                    <NavMenu>
                        <NavItem>
                            <NavLinks
                                to="about"
                                smooth={true}
                                duration={500}
                                spy={true}
                                exact='true'
                                offset={-80}
                            >About</NavLinks>
                        </NavItem>
                        {/* <NavItem>
                            <NavLinks
                                to="discover"
                                smooth={true}
                                duration={500}
                                spy={true}
                                exact='true'
                                offset={-80}
                            >Discover</NavLinks>
                        </NavItem> */}
                        <NavItem>
                            <NavLinks
                                to="services"
                                smooth={true}
                                duration={500}
                                spy={true}
                                exact='true'
                                offset={-150}
                            >Services</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinkRefund to="/refund-return-policy" exact='true'>Refund</NavLinkRefund>
                        </NavItem>
                        <NavItem>
                            <NavLinkRefund to="/terms" exact='true'>T&C</NavLinkRefund>
                        </NavItem>
                        <NavItem>
                            <NavLinkRefund to="/privacy" exact='true'>Privacy</NavLinkRefund>
                        </NavItem>

                    </NavMenu>
                    {/* <NavBtn>
                        <NavBtnLink to="/home">View Odds</NavBtnLink>
                        
                        <NavBtnLink to="/signup">Sign Up</NavBtnLink>
                    </NavBtn> */}
                </NavbarContainer>
            </Nav>
        </>
    );
};

export default Navbar
