import logo from './logo.svg';
import './App.css';

function loadScript(src) {
    return new Promise((resolve) => {
        const script = document.createElement('script')
        script.src = src
        script.onload = () => {
            resolve(true)
        }
        script.onerror = () => {
            resolve(false)
        }
        document.body.appendChild(script)
    })
}

function Razorpay() {

    async function displayRazorpay() {

        const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')

        if (!res) {
            alert('Razropay failed to load!!')
            return
        }

        const data = await fetch('http://127.0.0.1:5001/ashwavaarte-ae740/us-central1/razorpay', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "data": {
                    "amt": 180,
                    "receipt": "order_rcptid_11"
                }
            }),
        }).then((t) =>
            t.json()
        )

        const options = {
            "key": "rzp_test_iIdx1RpT9wGKhC", // Enter the Key ID generated from the Dashboard
            "amount": "50000", // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            "currency": "INR",
            "name": "Ashwa Vaarte",
            "description": "Test Transaction",
            // "image": "https://example.com/your_logo",
            "order_id": data.result.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
            // "callback_url": "http://localhost:1769/verify",
            "notes": {
                "address": "Razorpay Corporate Office"
            },
            "theme": {
                "color": "#3399cc"
            },
            "handler": async function (response) {
                // alert(response.razorpay_payment_id);
                // alert(response.razorpay_order_id);
                // alert(response.razorpay_signature)
            },
        };
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    }

    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" />
                <button
                    onClick={displayRazorpay}
                >
                    Pay now
                </button>
            </header>
        </div>
    );
}

export default Razorpay;