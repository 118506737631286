import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Box } from '@mui/system';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
function PersonalGuide() {
    return (
        <Card
            sx={{
                backgroundImage: 'url(pg.jpg)',
                backgroundSize: 'cover', // This ensures the image covers the whole card
                backgroundPosition: 'center', // This centers the image in the card
                opacity: 0.9,
            }}
        >
            <CardContent>
                <Typography
                    variant="h3"
                    color="white"
                    textAlign="center"
                    style={{
                        fontFamily: 'Georgia, serif', // Elegant font
                        fontSize: '1.5em', // Slightly larger font size
                        fontWeight: 'bold', // Bold text
                        letterSpacing: '0.05em', // Space out the letters a bit
                        textShadow: '1px 1px 2px rgba(0, 0, 0, 0.1)', // Subtle text shadow for depth
                        margin: '20px 0', // Add some margin above and below
                        padding: '10px', // Padding inside the element
                        backgroundColor: 'rgba(255, 255, 0, 1)', // Semi-transparent background
                        borderRadius: '8px', // Rounded corners
                        border: '1px solid #ddd', // Light border
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Soft shadow effect
                        // Add a red text border
                        color: 'red',
                        WebkitTextStroke: '1px red' // Works in WebKit browsers
                    }}
                >
                    Here you will be guided towards taking the right & perfect judgement call on the Final Selection along with guidance on Money Management.
                </Typography>

                <Box sx={{ my: 2, textAlign: 'center' }}>
                    <Typography variant="h4" style={{
                        fontFamily: 'Georgia, serif', // Elegant font
                        fontSize: '1.5em', // Slightly larger font size
                        fontWeight: 'bold', // Bold text
                        letterSpacing: '0.05em', // Space out the letters a bit
                        textShadow: '1px 1px 2px rgba(0, 0, 0, 0.1)', // Subtle text shadow for depth
                        margin: '20px 0', // Add some margin above and below
                        padding: '10px', // Padding inside the element
                        backgroundColor: 'rgba(255, 255, 0, 0.9)', // Semi-transparent background
                        borderRadius: '8px', // Rounded corners
                        border: '1px solid #ddd', // Light border
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Soft shadow effect
                        // Add a red text border
                        color: 'blue',
                        WebkitTextStroke: '1px red' // Works in WebKit browsers
                    }}>
                        All you would have to do is WhatsApp in your Name, Phone Number & Email ID to the below number
                    </Typography>

                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    {/* Update the button to include the WhatsApp icon and change the color */}
                    <Button
                        variant="contained"
                        startIcon={<WhatsAppIcon />}
                        style={{ backgroundColor: '#25D366', color: '#fff' }} // WhatsApp color
                    >
                        +91 861 899 5661
                    </Button>
                </Box>
            </CardContent>
        </Card>
    );
}

export default PersonalGuide;
