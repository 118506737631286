import { Outlet } from 'react-router-dom';
import TopBar from './TopBar';
const RenderLayout = () => {
    return (
        <>
            <TopBar />
            <Outlet />
        </>
    );
};

export default RenderLayout;