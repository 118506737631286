import { Box, Grid, InputLabel, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import convertStringToRace from './utilities';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useState } from 'react';

export default function OddsHeader(props) {
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: '#1589FF',
        ...theme.typography.body2,
        padding: theme.spacing(0.20),
        textAlign: 'center',
        color:'white',
        margin: theme.spacing(0.25),
        border: '2px solid #000000',
    }));

    const handleChange = (event) => {
        props.setChecked(event.target.checked)
    };

    return (
        <Stack direction="row" spacing={0.1}>
            <Item style={{ flex: 0.2 }}>
                <FormGroup>
                    <FormControlLabel
                        label="Show/Hide"
                        value="0"
                        labelPlacement="top"
                        control={<Switch
                            color="default"
                            checked={props.checked}
                            onChange={handleChange} //props.handleColumnVisibilityChange
                        />}
                    />
                </FormGroup>
            </Item>
            <Item style={{ fontWeight: 'bold', flex: 1 }}>
                <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                    {props.venue}
                </Typography>
                <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
                    {convertStringToRace(props.pageName)}
                </Typography>
            </Item>
            <Item style={{ fontWeight: 'bold', flex: 1 }}>
                <Typography variant="subtitle2" style={{ fontWeight: 'bold', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                    {props.horseStatus}
                </Typography>
            </Item>
            <Item style={{ fontWeight: 'bold', flex: 1 }}>
                <Typography variant="subtitle1" style={{ fontWeight: 'bold', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                    {props.textInputValue}
                </Typography>
            </Item>
        </Stack>
    )
}