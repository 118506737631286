import React, { useState,useEffect } from 'react'
import {
    HeroContainer,
    HeroBg,
    VideoBg,
    ArrowForward,
    HeroContent,
    HeroP,
    HeroTitle,
    HeroBtnWrapper,
    ArrowRight
} from '../Hero/HeroElements'
import { Card, CardContent, Typography, Grid } from '@mui/material';
import { AccountBalance, Face, Business, Assessment } from '@mui/icons-material';
import HeadphonesIcon from '@mui/icons-material/Headphones';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { collection, getDocs, query, where, onSnapshot } from "firebase/firestore";
import { db, storage } from '../../firebase';
import { Button } from '../../Utilities/ButtonElements'
import Video from '../../Videos/video.mp4'
import ReactPlayer from 'react-player'
import dayjs from 'dayjs';
const iconSize = 64; // Adjust the icon size as needed

const cardStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%', // Make the card take the full height
  textAlign: 'center',
  backgroundColor: '#C8E6C9'
};

const iconStyle = {
    fontSize: iconSize,
    marginBottom: '16px', // Adjust the spacing between icon and text
  };
  
  const shiningEffect = {
    ':hover': {
      boxShadow: '0 0 20px 10px rgba(255, 255, 255, 0.8), 0 0 40px 20px rgba(255, 255, 255, 0.6)',
    },
  };

  const buttonStyle = {
    color: 'black',
    padding:'10px',
    backgroundColor: '#01BF71',
};
  

const Hero = () => {

    const [hover, setHover] = useState(false);
    const [updateText, setUpdateText] = useState();
    const onHover = () => {
        setHover(!hover);
    }

    useEffect(() => {
        const textInputValueFbUpdate = query(collection(db, dayjs(new Date()).format('DDMMYYYY') + "updateText"));
        const unsubscribeStatusTextUpdate = onSnapshot(textInputValueFbUpdate, (querySnapshot) => {
          const linkTextFbUpdate = [];
          querySnapshot.forEach((doc) => {
            linkTextFbUpdate.push(doc.data());
          });
          linkTextFbUpdate.length > 0 ? setUpdateText(linkTextFbUpdate[0]?.text) : setUpdateText('');
        });
      }, []);

    return (
        <>
            <HeroContainer>
                <HeroBg>
                    <VideoBg autoPlay loop muted src={Video} type="video/mp4" />
                    
                </HeroBg>
                <HeroContent>
                <marquee style={{ color: 'red', marginTop: '200px',color:'white',backgroundColor:"green" ,fontSize:"xx-large"}}>
                {updateText}
                </marquee>

                {/* <HeroTitle>Offer extended till 15th April</HeroTitle> */}
                    {/* <HeroP>
                        Rs 150/day
                    </HeroP> */}
                    <HeroBtnWrapper>
                    <Grid container spacing={3}>
                    {/* Top Left Card */}
                    <Grid item xs={6} sm={3}>
                        <Card style={cardStyle}>
                        <CardContent>
                            <HeadphonesIcon fontSize="large" />
                            <Typography variant="h6">Listen to the pre-race analysis</Typography>
                            <Button
                                size="small"
                                style={buttonStyle}
                                href="/home"
                                to="login"
                            >View Selection
                            </Button>
                        </CardContent>
                        </Card>
                    </Grid>

                    {/* Top Right Card */}
                    <Grid item xs={6} sm={3}>
                        <Card style={cardStyle}>
                        <CardContent>
                            <LeaderboardIcon fontSize="large" />
                            <Typography variant="h6">Analyse handicap points</Typography>
                            <Button
                                style={buttonStyle}
                                href="/home"
                                to="login"
                            >View Selection
                            </Button>
                        </CardContent>
                        </Card>
                    </Grid>

                    {/* Bottom Left Card */}
                    <Grid item xs={6} sm={3}>
                        <Card style={cardStyle}>
                        <CardContent>
                            <TroubleshootIcon fontSize="large" />
                            <Typography variant="h6">Observe paddock opinion</Typography>
                            <Button
                                style={buttonStyle}
                                href="/home"
                                to="login"
                            >View Paddock
                            </Button>
                        </CardContent>
                        </Card>
                    </Grid>

                    {/* Bottom Right Card */}
                    <Grid item xs={6} sm={3}>
                        <Card style={cardStyle}>
                        <CardContent>
                            <TrendingUpIcon fontSize="large" />
                            <Typography variant="h6">Real time odds updated every 5 seconds</Typography>
                            <Button
                                style={buttonStyle}
                                href="/home"
                                to="login"
                            >View Odds
                            </Button>
                        </CardContent>
                        </Card>
                    </Grid>
                    </Grid>
                    </HeroBtnWrapper>
                    {/* <ReactPlayer url='https://www.youtube.com/watch?v=LXb3EKWsInQ' width={300} height={300}/> */}
                </HeroContent>
            </HeroContainer>
        </>
    )
}

export default Hero
