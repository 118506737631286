import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { addUserPhoneNumber } from './firebase';

const OtpVerify = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const configuration = {
      widgetId: "33676f71557a393030383035",
      tokenAuth: "401275T2RK91aJG64b28e9dP1",
      identifier: "<enter mobile number/email here>(optional)",
      success: (data) => {
        verifyAccessToken(data.message);
        navigate("/login");
      },
      failure: (error) => {
        console.log('failure reason', error);
      }
    };

    const initSendOTP = () => {
      const script = document.createElement('script');
      script.src = "https://control.msg91.com/app/assets/otp-provider/otp-provider.js";
      script.onload = () => {
        window.initSendOTP(configuration);
      };
      document.body.appendChild(script);
    };

    initSendOTP();

    const verifyAccessToken = async (token) => {
      try {
        const response = await fetch('https://control.msg91.com/api/v5/widget/verifyAccessToken', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            authkey: "401275A5YL15siw64b2d2ebP1",
            'access-token': token
          })
        });

        if (response.ok) {
          const data = await response.json();
          // Handle the response data
          addUserPhoneNumber(data.message);
        } else {
          // Handle the error
          throw new Error('Request failed with status ' + response.status);
        }
      } catch (error) {
        // Handle any errors
        console.error(error);
      }
    };
  }, []);



  return <div></div>;
};

export default OtpVerify;
