import React from 'react'
import { Box } from '@mui/material';
import about_us from '../../about_us.png'
import about_us_2 from '../../about_us_2.png'
import {
    InfoContainer,
    InfoWrapper,
    InfoRow,
    BtnWrap,
    Column1,
    Column2,
    Column3,
    Heading,
    ImgWrap,
    Subtitle,
    TextWrapper,
    TopLine,
    Img,
    AboutUs,
    Description,
    Welcome,
    InWelcome,
    P1,
    P2
} from '../About/InfoElements'

import { Button } from '../../Utilities/ButtonElements'

const AboutSection = ({
    lightBg,
    imgStart,
    topLine,
    lightText,
    darkText,
    headline,
    description,
    buttonLabel,
    alt,
    img,
    id,
    primary,
    dark,
    dark2,
}) => {

    return (
        <>
     {/* <Box
    component="img"
    sx={{
      height: 'auto', // Let the height adjust automatically based on the original aspect ratio
      width: '100%',  // Set the width to 100% of its container
      maxWidth: { xs: '100%', md: '100%', lg: '100%' }, // Ensure it doesn't exceed the container's width
      maxHeight: { xs: '300px', md: '600px', lg: '700px' }, // Limit the height as needed
    }}
    alt="The house from the offer."
    src={about_us}
  /> */}
  <InfoContainer lightBg={lightBg} id={id}>
  <InfoWrapper>
  <AboutUs>About Us</AboutUs>
  <Description>{headline}</Description>
  <Heading lightText={lightText}></Heading>
  <Welcome>
    <InWelcome>
        Welcome to Ashwa Vaarte
    </InWelcome>
  </Welcome>
  <P1>A single source for all things pertaining to the exciting world of horse racing. Get ready to embark on a journey towards becoming a master at winning. Whether you're a punter or just a wee pony, we've got something for everyone. 
</P1>

<P2>
If you're relatively new to the world of horse racing, it will make sense to start by getting a firm grasp on how everything moves. Unlike traditional methods, Ashwa Vaarte is always working towards giving you the latest & the most accurate information on the Indian Horse Racing sector, including the Real Time information about the Race Locations, Handicapping Points, Selection Info, Odds & other elements that have positive effects on the outcome of the race. 
</P2>
  </InfoWrapper>
  </InfoContainer>
     {/* <Box
    component="img"
    sx={{
      height: 'auto', // Let the height adjust automatically based on the original aspect ratio
      width: '100%',  // Set the width to 100% of its container
      maxWidth: { xs: '100%', md: '100%', lg: '100%' }, // Ensure it doesn't exceed the container's width
      maxHeight: { xs: '300px', md: '600px', lg: '700px' }, // Limit the height as needed
    }}
    alt="The house from the offer."
    src={about_us_2}
  /> */}
            {/* <InfoContainer lightBg={lightBg} id={id}>
                <InfoWrapper> */}
                    {/* <InfoRow imgStart={imgStart}> */}
                        {/* <Column1> */}
                            {/* <TextWrapper> */}
                                {/* <TopLine>{topLine}</TopLine>
                                <Heading lightText={lightText}>{headline}</Heading>
                                <Subtitle darkText={darkText}>{description}</Subtitle><br /> */}
                                {/* <BtnWrap>
                                    <Button to="home"
                                        smooth={true}
                                        duration={500}
                                        spy={true}
                                        exact={true}
                                        offset={-80}
                                        primary={primary ? 1 : 0}
                                        dark={dark ? 1 : 0}
                                        dark2={dark2 ? 1 : 0}
                                    >{buttonLabel}</Button>
                                </BtnWrap> */}
                            {/* </TextWrapper> */}
                        {/* </Column1> */}
                        {/* <Column2>
                            <ImgWrap>
                                <Img src={img} alt={alt} />
                            </ImgWrap>
                        </Column2> */}
                    {/* </InfoRow> */}
                {/* </InfoWrapper>
            </InfoContainer> */}
        </>
    )
}

export default AboutSection
