
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from './firebase';
import { useEffect, useState } from "react";
import { CircularProgress } from '@mui/material';
import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { initializeApp } from "firebase/app"
import OddsAdmin from "./OddsAdmin";
import OddsUser from "./OddsUser";
import { useLocation } from 'react-router-dom';
import { db } from "./firebase";

function Odds() {
    const location = useLocation();
    const { inputValue } = location.state || { inputValue: 'Race1' };
    const [user, loading, error] = useAuthState(auth);
    const [admin, setAdmin] = useState(false);
    const navigate = useNavigate();
    const fetchUserName = async () => {
        try {
            const q = query(collection(db, "users"), where("uid", "==", user?.uid));
            const doc = await getDocs(q);
            const data = doc.docs[0].data();
            if (data.admin) {
                setAdmin(true);
            }
        } catch (err) {
            console.error(err);
            alert("An error occured while fetching user data");
        }
    };

    useEffect(() => {
        if (loading) <CircularProgress />;
        
        if (!user) return navigate("/login");
        fetchUserName();
    }, [user, loading]);
    
    return (
        <>
            {admin ? <OddsAdmin inputValue={inputValue} /> : user ? <OddsUser inputValue={inputValue}/> : '' }
        </>
    );

}

export default Odds;