import styled, { keyframes } from 'styled-components'
import horsebag from '../../horsebag2.png';
export const InfoContainer = styled.div`
color: #fff;
@media screen and (max-width:768px) {
    padding: 100px 0;
}
background-image: url(${horsebag}); 
background-size: cover; 
background-position: center; 
background-repeat: no-repeat;
`

export const InfoWrapper = styled.div`
display: grid;
z-index: 1;
height: auto;
width: 100%;
max-width: 90%;
margin-right: auto;
margin-left: auto;
padding: 50px 30px;
text-align:center;

`

export const InfoRow = styled.div`
display: grid;
grid-auto-columns: minmax(auto, 1fr);
align-items: center;
grid-template-areas: ${({ imgStart }) => (imgStart ? `'col3 col2 col1'` : `'col1 col2 col3'`)};

@media screen and (max-width:768px) {
    grid-template-areas: ${({ imgStart }) => (imgStart ? `'col1' 'col2' 'col3'` : `'col1 col1 col1' 'col2 col2 col2' 'col3 col3 col3'`)};
}
`

export const Column1 = styled.div`
margin-bottom: 15px;
border:1px solid green;
padding: 0 15px;
grid-area: col1;
width:100%;
`

export const Column2 = styled.div`
margin-bottom: 15px;
padding: 0 15px;
grid-area: col2;
`

export const Column3 = styled.div`
margin-bottom: 15px;
padding: 0 15px;
grid-area: col3;
`

export const TextWrapper = styled.div`
max-width: 540px;
padding-top: 0;
padding-bottom: 60px;
`

export const TopLine = styled.p`
text-align: center;
margin-top:10px;
color: #01bf71;
font-size: 16px;
line-height:16px;
font-weight: 700;
letter-spacing: 1.4px;
text-transform: uppercase;
margin-bottom: 16px;
`
export const Heading = styled.h1`
color: #000;
font-family: monospace;
font-size: 26px;
line-height:1.1;
font-weight: 600;
color: ${({ lightText }) => (lightText ? '#f7f8fa' : '#010606')};


@media screen and (max-width:480px) {
    font-size: 32px;
}
`

export const Subtitle = styled.p`
max-width: 100&;
font-family: Arial, sans-serif;
margin-bottom: 35px;
margin: 0 auto;
font-size: 18px;
line-height: 1.5;
color: ${({ darkText }) => (darkText ? '#010606' : '#fff')};

`

export const BtnWrap = styled.div`
display: flex;
justify-content: flex-start;
`

export const ImgWrap = styled.div`
max-width: 555px;
height: 100%;
`

export const Img = styled.img`
width: 100%;
margin: 0 0 10px 0;
padding-right: 0;
`

export const AboutUs = styled.h1`

position: relative;
transform: translate(-50%,-50%);
top: 50%;
left: 50%;
font-family: 'Times New Roman',serif;
letter-spacing: 5px;
font-size: 100px;
font-weight: bold;
background-image: linear-gradient(
 to right,
 #462523 0,
#cb9b51 22%, 
 #f6e27a 45%,
 #f6f2c0 50%,
 #f6e27a 55%,
 #cb9b51 78%,
 #462523 100%
 );
color:transparent;
-webkit-background-clip:text;
`

export const Description = styled.h1`

`

export const Welcome = styled.h1`
font-size:50px;
text-align: center; 
color: #f1f1f1;
margin: 20px 10px; 
`
export const InWelcome =  styled.span`
text-shadow: 0px 4px 3px rgba(0,0,0,0.4),
0px 8px 13px rgba(0,0,0,0.1),
0px 18px 23px rgba(0,0,0,0.1);
background: rgb(1,191,113);

`
export const P1 = styled.p`
margin-top:20px;
font-size:20px;
`
export const P2 = styled.p`
margin-top:20px;
font-size:20px;
`