import React from 'react';
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

const plans = [
  { name: 'SILVER PLAN', paddockOpinion: true, viewOdds: false, selections: false, price: '₹100' },
  { name: 'GOLD PLAN', paddockOpinion: false, viewOdds: true, selections: false, price: '₹200' },
  { name: 'DIAMOND PLAN', paddockOpinion: true, viewOdds: true, selections: true, price: '₹500', offerPrice: '₹250' }
];

const SubscriptionTable = () => (
  <TableContainer component={Paper}>
    <Table>
      <TableHead style={{ backgroundColor: '#1976d2', color: 'white' }}>
        <TableRow>
          <TableCell>Plan</TableCell>
          <TableCell align="center">Paddock Opinion</TableCell>
          <TableCell align="center">View Odds</TableCell>
          <TableCell align="center">Selections</TableCell>
          <TableCell align="center">Pricing</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {plans.map((plan) => (
          <TableRow key={plan.name}>
            <TableCell component="th" scope="row">
              {plan.name}
            </TableCell>
            <TableCell align="center">{plan.paddockOpinion ? <CheckIcon style={{ color: 'green' }} /> : <CloseIcon style={{ color: 'red' }} />}</TableCell>
            <TableCell align="center">{plan.viewOdds ? <CheckIcon style={{ color: 'green' }} /> : <CloseIcon style={{ color: 'red' }} />}</TableCell>
            <TableCell align="center">{plan.selections ? <CheckIcon style={{ color: 'green' }} /> : <CloseIcon style={{ color: 'red' }} />}</TableCell>
            <TableCell align="center">
              <Typography component="div">
                {plan.offerPrice ? (
                  <div>
                    <span style={{ textDecoration: 'line-through' }}>{plan.price}</span>
                    <span>{plan.offerPrice}</span>
                  </div>
                ) : (
                  plan.price
                )}
              </Typography>
              {plan.offerPrice && (
                <Button variant="outlined" color="secondary">
                  Special Offer
                </Button>
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

export default SubscriptionTable;
