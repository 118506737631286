import React, { useState, useEffect } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { Form, FormikProvider, useFormik } from "formik";
import { logInWithEmailAndPassword, auth } from './firebase';
import * as Yup from "yup";
import { db } from './firebase';
import {
  query,
  getDocs,
  collection,
  where
} from "firebase/firestore";

import "./styles.css";
import { useAuthState } from "react-firebase-hooks/auth";

import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Link,
  Alert,
  Snackbar,
  Stack,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Icon } from "@iconify/react";
import { motion } from "framer-motion";

let easing = [0.6, -0.05, 0.01, 0.99];
const animate = {
  opacity: 1,
  y: 0,
  transition: {
    duration: 0.6,
    ease: easing,
    delay: 0.16,
  },
};

const LoginForm = ({ setAuth }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/home";
  const [showPassword, setShowPassword] = useState(false);
  const [autoHideDuration, setAutoHideDuration] = useState(6000);
  const [user, loading, error] = useAuthState(auth);

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (user) return navigate("/home");
  }, [user, loading]);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Provide a valid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const q = query(collection(db, "users"), where("email", "==", values.email));
        const querySnapshot = await getDocs(q);
        var enable;
        querySnapshot.forEach((doc) => {
          enable = doc.data().enable;
        });
        if (enable === undefined || enable === "0" || enable === 0) {
          setSnackbarMessage("Account is disabled. Please contact 8618995661");
          setSnackbarOpen(true);
          setAutoHideDuration(12000)
          return
        }
        const userCredential = await logInWithEmailAndPassword(values.email, values.password);
        if (userCredential && userCredential.user) {
          //setAuth(true);
          navigate(from, { replace: true });
        }
      } catch (err) {
        if (err.code === 'auth/invalid-email') {
          setSnackbarMessage("Invalid UserName or Password");
          setSnackbarOpen(true);
        } else if (err.code === 'auth/user-disabled') {
          setSnackbarMessage("Please contact the admin");
          setSnackbarOpen(true);
        } else if (err.code === 'auth/user-not-found') {
          setSnackbarMessage("Invalid User. Please sign up");
          setSnackbarOpen(true);
        } else if (err.code === 'auth/wrong-password') {
          setSnackbarMessage("Invalid UserName or Password");
          setSnackbarOpen(true);
        } else {
          setSnackbarMessage("Invalid UserName or Password. Please contact admin");
          setSnackbarOpen(true);
        }

      } finally {
        // setSubmitting(false);
      }

    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } =
    formik;

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  return (
    <>
      <Snackbar open={snackbarOpen} autoHideDuration={autoHideDuration} onClose={handleCloseSnackbar}>
        <Alert severity="error" onClose={handleCloseSnackbar}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Box
            component={motion.div}
            animate={{
              transition: {
                staggerChildren: 0.55,
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 3,
              }}
              component={motion.div}
              initial={{ opacity: 0, y: 40 }}
              animate={animate}
            >
              <TextField
                fullWidth
                autoComplete="username"
                type="email"
                label="Email Address"
                {...getFieldProps("email")}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />

              <TextField
                fullWidth
                autoComplete="current-password"
                type={showPassword ? "text" : "password"}
                label="Password"
                {...getFieldProps("password")}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword((prev) => !prev)}
                      >
                        {showPassword ? (
                          <Icon icon="eva:eye-fill" />
                        ) : (
                          <Icon icon="eva:eye-off-fill" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>

            <Box
              component={motion.div}
              initial={{ opacity: 0, y: 20 }}
              animate={animate}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ my: 2 }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      {...getFieldProps("remember")}
                      checked={values.remember}
                    />
                  }
                  label="Remember me"
                />

                <Link
                  component={RouterLink}
                  variant="subtitle2"
                  to="/reset"
                  underline="hover"
                >
                  Forgot password?
                </Link>
              </Stack>

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                {isSubmitting ? "loading..." : "Login"}
              </LoadingButton>

            </Box>
          </Box>

        </Form>
      </FormikProvider>
      <div>
        <a href="https://api.whatsapp.com/send?phone=+918618995661&text=Hi" className="float" target="_blank" rel="noreferrer">
          <i className="fa fa-whatsapp my-float"></i>
        </a>
      </div>
    </>
  );
};

export default LoginForm;