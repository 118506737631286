
import React, { useState, useContext, useEffect } from 'react';
import { Tab, Tabs, Button } from '@mui/material';
import OddsAdmin from './OddsAdmin';
import OddsUser from './OddsUser';
import OddsPaddockSelection from './OddsPaddockSelection';
import OddsHorsePaddock from './OddsHorsePaddock';
import MyContext from './MyContext';
import { useLocation } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { query, onSnapshot, collection } from "firebase/firestore";
import { db } from './firebase';
import OddsPaddockSelectionPercentage from './OddsPaddockSelectionPercentage';

const TabNavOdds = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [youtubeText, setYoutubeText] = useState();
    const { sharedDataDate, sharedDataVenue } = useContext(MyContext);
    const [raceNumber, setRaceNumber] = useState(1);
    const [dateValue, setDateValue] = sharedDataDate;
    const [venue, setVenue] = sharedDataVenue;
    const oddsDateValue = dateValue;
    const numberOfRaces = 10;
    const { state } = useLocation();
    const { admin, link } = state
    const navigate = useNavigate();
    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
        setRaceNumber(newValue + 1)
    };

    useEffect(() => {
        const textInputValueFb = query(collection(db, oddsDateValue + "link"));
        const unsubscribeStatusText = onSnapshot(textInputValueFb, (querySnapshot) => {
            const linkTextFb = [];
            querySnapshot.forEach((doc) => {
                linkTextFb.push(doc.data());
            });
            linkTextFb.length > 0 ? setYoutubeText(linkTextFb[0]?.text) : setYoutubeText('');
        });
    }, [oddsDateValue]);

    const openYouTubeLink = () => {
        window.open(youtubeText, '_blank');
      };

    const tabLabels = Array.from({ length: numberOfRaces }, (_, index) => `Race ${index + 1}`);
    return (
        <div>
            <Button variant="contained" startIcon={<ArrowBackIcon />} onClick={() => navigate("/home")} style={{ margin: '10px' }}>
                Back
            </Button>
            <Button variant="contained" color="primary"  style={{ backgroundColor: 'red', color: 'white' }} onClick={openYouTubeLink}>
                Open YouTube Live Stream
            </Button>
            <Tabs
                value={activeTab}
                onChange={handleTabChange}
                variant="scrollable"
                allowScrollButtonsMobile
                scrollButtons="auto">
                {tabLabels.map((label, index) => (
                    <Tab key={index} label={label} />
                ))}
            </Tabs>
            {
                link === "odds" ? (
                    <div>
                        {admin ? (
                            <OddsAdmin raceNumber={raceNumber} oddsDateValue={oddsDateValue} venue={venue} />
                        ) : (
                            <OddsUser raceNumber={raceNumber} oddsDateValue={oddsDateValue} venue={venue} />
                        )}
                    </div>
                ) : link === "selection" ? (
                    <OddsPaddockSelection raceNumber={raceNumber} oddsDateValue={oddsDateValue} venue={venue} />
                ) : link === "paddock" ? (
                    <OddsHorsePaddock raceNumber={raceNumber} oddsDateValue={oddsDateValue} venue={venue} />
                ) : link === "percentage" ? (
                    <OddsPaddockSelectionPercentage raceNumber={raceNumber} oddsDateValue={oddsDateValue} venue={venue}/>
                ) : null
            }



            {/* <TabComponent value={activeTab} /> */}
        </div>
    );

}

export default TabNavOdds;