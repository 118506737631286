import React from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

function AlertComponent({ isSuccess }) {
  return (
    <div>
      {isSuccess ? (
        <Alert severity="success">
          <AlertTitle>Success</AlertTitle>
           Odds Exists
        </Alert>
      ) : (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          Wrong Odds Selected
        </Alert>
      )}
    </div>
  );
}

export default AlertComponent;
