import React from 'react';

function Privacy() {
    const cardStyle = {
        backgroundColor: '#fff', // Card background color
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)', // Shadow for card effect
        margin: '20px auto', // Margin for center alignment
        padding: '50px', // Padding inside the card
        maxWidth: '800px', // Maximum width of the card
        textAlign: 'left', // Align text to left within the card
        borderRadius: '10px' // Rounded corners for the card
    };
    const styles = {
        list: {
            listStyleType: 'none', // Removes default list styling
            padding: 0,
            color: '#333', // Example text color, adjust as needed
            fontFamily: 'Arial, sans-serif'
        },
        listItem: {
            marginBottom: '10px', // Spacing between list items
            fontSize: '16px', // Adjust font size as needed
        },
        bulletPoint: {
            fontWeight: 'bold', // Makes the bullet point number bold
            marginRight: '5px' // Spacing between the bullet point and text
        },
        container: {
            maxWidth: '800px',
            margin: 'auto',
            fontFamily: 'Arial, sans-serif',
            color: '#333'
        },
        header: {
            color: '#2a9d8f',
            textAlign: 'center',
            marginBottom: '10px'
        },
        subHeader: {
            color: '#264653',
            textAlign: 'center',
            marginBottom: '10px'
        },
        sectionHeader: {
            marginTop: '10px',
            color: '#e76f51',
            marginBottom: '10px'
        },
        paragraph: {
            textAlign: 'justify',
            lineHeight: 1.6
        }
    };
    return (
        <div style={cardStyle}>
            <h1 style={styles.header}>Ashwa Vaarte</h1>
            <h2 style={styles.subHeader}>PRIVACY POLICY</h2>

            <h3 style={styles.sectionHeader}>Scope of this policy:</h3>
            <p style={styles.paragraph}>This privacy policy (the “Policy” or the "Privacy Policy”) describes how our Ashwa Vaarte
                website collects, uses, consults or otherwise processes an individual's Personal Data. For the
                purposes of this policy, "Ashwa Vaarte Website" refers to Ashwa Vaarte., a company
                incorporated under the law of the state of Karnataka, having its registered seat and its office
                at No. 2559, 2nd Cross, KG Koppal, Mysuru, Karnataka – 570009, and email address
                infoashwavaarte@gmail.com. In all cases described in this Policy, Ashwa Vaarte will process
                your personal data as a data Controller.
                We are committed to protecting the privacy of our users and customers. This Privacy Policy is
                especially directed at:
                <ul style={styles.list}>
                    <li style={styles.listItem}>Provide, operate, and maintain our website</li>
                    <li style={styles.listItem}>Improve, personalize, and expand our website</li>
                    <li style={styles.listItem}>Understand and analyse how you use our website</li>
                    <li style={styles.listItem}>Develop new products, services, features, and functionality</li>
                    <li style={styles.listItem}>Communicate with you, either directly or through one of our partners, including for
                        customer service, to provide you with updates and other information relating to the
                        website, and for marketing and promotional purposes</li>
                    <li style={styles.listItem}>Send you promotional emails</li>
                    <li style={styles.listItem}>Find and prevent fraud</li>
                </ul>
                This Privacy Policy is intended to inform you how we gather, define, and use information that
                could identify you, such as your name, email address, address, other contact details, online
                identifiers or other information that you provide to us when using our websites or when
                relying on our services. Please take a moment to read this Privacy Policy carefully. This policy
                is to be read as consistent with the Cookie Policy. This policy includes a description of your
                data protection rights.
            </p>

            <h3 style={styles.sectionHeader}>Contacting us:</h3>
            <p style={styles.paragraph}>If you have general questions about your account or how to contact Customer Service for
                assistance, please send us an email at infoashwavaarte@gmail.com. Please note that if you
                contact us to assist you, for your safety and ours we may need to authenticate your identity
                before fulfilling your request.
            </p>


            <h3 style={styles.sectionHeader}>Collection of Information</h3>
            <p style={styles.paragraph}>
                The purpose of the Website is to create an online community of professionals involved in the
                horse racing industry who want to learn about the right & formulated way of placing wagers
                and/or newcomers who are eager to know about the horse racing industry and about the
                different ways to place wagers without incurring major losses.
                As part of our effort to create this online community, we receive and store information about
                you such as:
                <ul style={styles.list}>
                    <li style={styles.listItem}> Personal Information</li>
                    <li style={styles.listItem}> First and Last Name</li>
                    <li style={styles.listItem}> Email Address</li>
                    <li style={styles.listItem}> Mobile Number</li>
                </ul>
                You are required to provide us with additional information and consent to our terms as
                described here which are part of our general TOS and Privacy Policy.
            </p>


            <h3 style={styles.sectionHeader}>Log Files:</h3>
            <p style={styles.paragraph}>
                Ashwa Vaarte follows a standard procedure of using log files. These files log visitors when they
                visit websites. All hosting companies do this and a part of hosting services' analytics. The
                information collected by log files include internet protocol (IP) addresses, browser type,
                Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the
                number of clicks. These are not linked to any information that is personally identifiable. The
                purpose of the information is for analysing trends, administering the site, tracking users'
                movement on the website, and gathering demographic information.
            </p>

            <h3 style={styles.sectionHeader}>Sharing Your Information</h3>
            <p style={styles.paragraph}>
                We do not trade, sell or otherwise share your information with third parties except as set out
                in this Privacy Policy. We may share information with the parties and for the purposes set out
                below:
                <ul style={styles.list}>
                    <li style={styles.listItem}>  Companies in the same group as the Controller.</li>
                    <li style={styles.listItem}>Trusted external third-party service providers for analytics purposes, website
                        performance and IT and system administration services. This data is non-identifiable
                        personal data and anonymised in relation to IP addresses for visitors in the Indian Sub-Continent</li>
                    <li style={styles.listItem}>  Professional advisers who are providing professional services to us.</li>
                    <li style={styles.listItem}> Third parties to whom we may choose to sell, transfer or merge parts of our business
                        or our assets. Alternatively, we may seek to acquire other businesses or merge with
                        them. If a change happens to our business, then the new owners may use your
                        information in the same way as set out in this privacy policy.</li>
                    <li style={styles.listItem}>Business advisers, auditors, affiliates and subsidiaries as part of our day-to-day
                        activities when running the website. We may need to share your information if we
                        have a good faith belief that disclosure of such information is helpful or reasonably
                        necessary to: </li>
                    <ul>
                        <li>
                            comply with any applicable law, regulation legal process or governmental
                            request;
                        </li>
                        <li>
                            investigate, detect, prevent, or take action regarding illegal activities or other
                            wrongdoing, suspected fraud or security issues;
                        </li>
                        <li>
                            to establish or exercise our rights to defend against legal claims;
                        </li>
                        <li>
                            prevent harm to the rights, property or safety of us, our users, yourself or any
                            third party;
                        </li>
                        <li>
                            enforcement and protection of intellectual property rights; or
                        </li>
                        <li>
                            for the purpose of collaborating with law enforcement agencies.
                        </li>
                    </ul>
                </ul>
                We ensure that all third parties have measures in place to protect the security of your
                information and process it according to the law. We only allow third party service providers
                to use your information for specific purposes and subject to our specific instructions and
                a duty of confidentiality.

            </p>

            <h3 style={styles.sectionHeader}> Online Privacy Policy Only</h3>
            <p style={styles.paragraph}>
                This Privacy Policy applies only to our online activities and is valid for visitors to our website
                with regards to the information that they shared and/or collect in our Ashwa Vaarte website.
                This policy is not applicable to any information collected offline or via channels other than this
                website.

            </p>
            <h3 style={styles.sectionHeader}>Data Security</h3>
            <p style={styles.paragraph}>
                The security of the website and any data and information we collect is of upmost important to
                us. We take care to implement and maintain appropriate security of the website and the data
                and information we collect. This includes industry standard procedures and policies to prevent
                your information from being accidentally lost, used or accessed in an unauthorised way,
                altered or disclosed. We have put in place procedures to deal with any suspected personal
                data breach and will notify you and any applicable regulator of a breach where we are legally
                required to do so.
            </p>
            <h3 style={styles.sectionHeader}>Updates to Privacy Policy</h3>
            <p style={styles.paragraph}>
                We reserve the right to modify, alter or update this privacy policy from time to time, in our
                sole discretion and the most current version will always be posted on our website.
            </p>
            <h3 style={styles.sectionHeader}>Applicable Law</h3>
            <p style={styles.paragraph}>
                The validity and interpretation of this Privacy Policy shall be governed by the laws of the state
                of Karnataka, without regard to Karnataka's choice of law principles.
            </p>

            <h3 style={styles.sectionHeader}> Children's Information</h3>
            <p style={styles.paragraph}>
                Another part of our priority is adding protection for children while using the internet. We
                encourage parents and guardians to observe, participate in, and/or monitor and guide their
                online activity. The Top Bookies. Com does not knowingly collect any Personal Identifiable
                Information from children under the age of 18. If you think that your child provided this kind
                of information on our website, we strongly encourage you to contact us immediately and we
                will do our best efforts to promptly remove such information from our records.
            </p>
            <h3 style={styles.sectionHeader}> Data Retention</h3>
            <p style={styles.paragraph}>
                We will only retain Personal Information for so long as necessary to fulfil the purposes we
                collected it for which may include the purpose of satisfying legal, accounting or reporting
                requirements. The retention periods applied to Personal Information will be determined based
                on:
                <ul style={styles.list}>
                    <li style={styles.listItem}> the amount, nature and sensitivity of the Personal Information</li>
                    <li style={styles.listItem}>the potential risk of harm from unauthorised use or disclosure of your Personal
                        Information
                    </li>
                    <li style={styles.listItem}>the purposes for which we process your Personal Information and whether we can
                        achieve the purpose through other means
                    </li>
                    <li style={styles.listItem}> applicable legal requirements.</li>
                </ul>
                In certain circumstances you have a legal right to ask us to erase your data. We may use Non-personal Information indefinitely without further notice to yo
            </p>
        </div>
    );
}


export default Privacy;
