import React, { useState, useRef, useEffect, useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import DatePickerHorse from './DatePickerHorse';
import { Alert, Button, Grid, InputLabel, MenuItem, Select, Snackbar, Input, TextField } from '@mui/material';
import dayjs from 'dayjs';
import { collection, getDocs, setDoc, doc, deleteDoc, addDoc } from 'firebase/firestore';
import { db, storage } from './firebase';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SaveIcon from '@mui/icons-material/Save';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { TextareaAutosize } from '@mui/material';
import CustomDropdownComponent from './CustomDropdownComponent';
import { Tab, Tabs, Typography } from '@mui/material';
import { query, onSnapshot } from "firebase/firestore";
import ExcelManager from './ExcelManager';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import ColorComponent from './ColorComponent';
import { SketchPicker } from 'react-color';
import { ref, uploadBytes } from "firebase/storage";
import { auth } from './firebase';
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const RaceHorseManagement = () => {
    const navigate = useNavigate();
    const [user, loading, error] = useAuthState(auth);
    const [admin, setAdmin] = useState(false);
    const [selectedFilePre, setSelectedFilePre] = useState(null);
    const [selectedFilePost, setSelectedFilePost] = useState(null);
    const [pageName, setPageName] = useState();
    const [rowData, setRowData] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [message, setMessage] = React.useState();
    const [severity, setSeverity] = React.useState("success");
    const [textValue, setTextValue] = useState('');
    const [currDate, setCurrDate] = React.useState(dayjs(new Date()));
    const gridRef = useRef(null);
    const [oddsDateValue, setOddsDateValue] = useState(dayjs(currDate).format('DDMMYYYY'));
    const [paddockColors, setPaddockColors] = useState([]);

    const [activeTab, setActiveTab] = useState(0);
    const [raceNumber, setRaceNumber] = useState(1);
    const tabLabels = Array.from({ length: 10 }, (_, index) => `Race ${index + 1}`);
    const [raceHorses, setRaceHorses] = useState([]);
    const [horseStatus, sethorseStatus] = useState('');
    const [finalJudgementRowData, setFinalJudgementRowData] = useState([]);
    const [horseOptions, setHorseOptions] = useState([]);

    const [statusOptions, setStatusOptions] = useState([]);
    const [horseStatusText, sethorseStatusText] = useState('');
   

    const onGridReady = (params) => {
        gridRef.current = params.api;
        gridRef.current.sizeColumnsToFit();
    };

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
        setRaceNumber(newValue + 1)
    };


    useEffect(() => {
        var emails = ["anilkavali601@gmail.com", "jaykeerti123@gmail.com"]
        if (emails.includes(user.email)) {
            setAdmin(true);
        }
    }, [user])

    useEffect(() => {
        const filteredHorses = rowData.filter((race) => race.raceNumber === raceNumber)
        setRaceHorses(filteredHorses)
        setPageName(oddsDateValue + "Race" + raceNumber);
        setHorseOptions(filteredHorses.map(item => `H` + item.horseNumber))
        setFinalJudgementRowData([
            {
                id: 1,
                horseNameFJ: ''
            },
            {
                id: 2,
                horseNameFJ: ''
            },
        ]);
    }, [raceNumber, rowData])

    useEffect(() => {
        const firebaseDataName = oddsDateValue + "Race" + raceNumber + "Status";
        const horseStatusFb = query(collection(db, firebaseDataName));
        const unsubscribeHorseStatus = onSnapshot(horseStatusFb, (querySnapshot) => {
            const horseStatusFb = [];
            querySnapshot.forEach((doc) => {
                horseStatusFb.push(doc.data());
            });
            sethorseStatus(horseStatusFb[0]?.horseStatusData);
        });

        const textInputValueFb = query(collection(db, oddsDateValue + "Race" + raceNumber + "StatusText"));
        const unsubscribeStatusText = onSnapshot(textInputValueFb, (querySnapshot) => {
            const horseStatusTextFb = [];
            querySnapshot.forEach((doc) => {
                horseStatusTextFb.push(doc.data());
            });
            horseStatusTextFb.length > 0 ? sethorseStatusText(horseStatusTextFb[0]?.text) : sethorseStatusText('');
        });


    }, [raceNumber, oddsDateValue])

    const clearAllRowData = async () => {
        if (gridRef.current) {
            gridRef.current.setRowData([]);
        }
        await getDocs(collection(db, oddsDateValue + "horseDetails"))
            .then((querySnapshot) => {
                querySnapshot.docs.map((docFb) => (
                    deleteDoc(doc(db, oddsDateValue + "horseDetails", docFb.id))
                ));
            });

        setOpen(true);
        setSeverity("warning")
        setMessage("Data Deleted");
    };
    const handleTextChange = (event) => {
        setTextValue(event.target.value);
    };

    const handleAddRow = () => {
        const rows = textValue.split('\n').map((row) => {
            const [raceNumber, horseNumber, horseName, horseScore] = row.split('\t');
            const parsedRaceNumber = parseInt(raceNumber);
            const parsedHorseNumber = parseInt(horseNumber);


            if (Number.isNaN(parsedRaceNumber) || Number.isNaN(parsedHorseNumber)) {
                return null; // Skip row if raceNumber or horseNumber is not a valid number
            }

            return {
                raceNumber: parsedRaceNumber,
                horseNumber: parsedHorseNumber,
                horseName,
                score: horseScore,
                horseColor: 'white'
            };
        }).filter((row) => row !== null);

        if (!rows.length) {
            setOpen(true);
            setSeverity("error")
            setMessage("No data found please paste some data in textbox")
            return
        }
        const newRowData = [...rowData, ...rows];
        setRowData(newRowData);
        setTextValue('');
        setOpen(true);
        setSeverity("success")
        setMessage("Excel data has been imported to table")
        handleCloseDialog()
    };

    useEffect(() => {
        const formattedDate = dayjs(currDate).format('DDMMYYYY');
        setOddsDateValue(formattedDate);
    }, [currDate]);

    useEffect(() => {
        const getData = async () => {
            await getDocs(collection(db, "paddockDetails"))
                .then((querySnapshot) => {
                    const newData = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
                    newData.sort((a, b) => a.id - b.id);
                    setPaddockColors(newData);
                    setStatusOptions(newData.map(obj => obj.paddockOpinion));
                });
        };
        getData();
    }, [])

    useEffect(() => {
        const getData = async (dateValue) => {
            let firebaseDataExists = 0;
            await getDocs(collection(db, dateValue + "horseDetails"))
                .then((querySnapshot) => {
                    const newData = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
                    const sortedHorses = newData.sort((a, b) => {
                        if (a.raceNumber !== b.raceNumber) {
                            return a.raceNumber - b.raceNumber;
                        } else {
                            return a.horseNumber - b.horseNumber;
                        }
                    });
                    setRowData(sortedHorses);
                    firebaseDataExists = newData.length;
                });
            if (firebaseDataExists) {
                setOpen(true);
                setSeverity("success")
                setMessage("Data Loaded Successfully")
            } else {
                setOpen(true);
                setSeverity("warning")
                setMessage("No Data Found")
            }
            const r = query(collection(db, oddsDateValue + "Race" + raceNumber + "finalData"));
            const unsubscribefj = onSnapshot(r, (querySnapshotFj) => {
                const rowsFj = [];
                querySnapshotFj.forEach((doc) => {
                    rowsFj.push(doc.data());
                });
                if (rowsFj.length > 0) {
                    rowsFj.sort((a, b) => a.id - b.id);
                    setFinalJudgementRowData(rowsFj);
                }
            });


        };
        getData(oddsDateValue);
    }, [oddsDateValue]);

    const favstatusOptions = ['',
        '1st favourite',
        '2nd favourite',
        'Special'
    ];

    const predefinedValues = ['HORSES ARE ARRIVING',
        'HORSES ARE PARADING IN THE PADDOCK',
        'JOCKEYS MOUNTING',
        'HORSES ARE LEAVING THE PADDOCK',
        'HORSES REACHED THE STARTING GATE',
        'JOCKEYS MOUNTING',
        'LOADING STARTED',
        'RACE STARTED',
        'UNOFFICIAL RESULT',
        'FINAL RESULT',];

    const handlePaddockOpinionChange = useCallback((params, newValue) => {
        const updatedRowData = rowData.map((row) => {
            if (row.horseNumber === params.data.horseNumber && row.raceNumber === params.data.raceNumber) {

                return { ...row, paddockOpinion: newValue, paddockColor: paddockColors.find(item => item.paddockOpinion === newValue)?.paddockColor || null };
            }
            return row;
        });
        setRowData(updatedRowData);
    }, [rowData]);

    const handleColorChangeForHorse = (params, newValue) => {
        const updatedRowData = rowData.map((row) => {
            if (row.horseNumber === params.data.horseNumber && row.raceNumber === params.data.raceNumber) {
                return { ...row, horseColor: newValue };
            }
            return row;
        });
        setRowData(updatedRowData);
    };




    const handlefavouriteChange = useCallback((params, newValue) => {
        const updatedRowData = rowData.map((row) => {
            if (row.horseNumber === params.data.horseNumber && row.raceNumber === params.data.raceNumber) {
                return { ...row, favourite: newValue };
            }
            return row;
        });
        setRowData(updatedRowData);
    }, [rowData]);


    const handleScoreChange = useCallback((params, newValue) => {
        const updatedRowData = rowData.map((row) => {
            if (row.horseNumber === params.data.horseNumber && row.raceNumber === params.data.raceNumber) {
                return { ...row, score: newValue };
            }
            return row;
        });
        setRowData(updatedRowData);
    }, [rowData]);



    const columnDefs = [
        { headerName: 'Race #', field: 'raceNumber', flex: 1, editable: admin, filter: true, },
        { headerName: 'Horse #', field: 'horseNumber', flex: 1, editable: admin, filter: true, },
        { headerName: 'Horse Name', field: 'horseName', flex: 1, editable: admin, filter: true, },
        {
            headerName: 'Horse Color',
            field: 'horseColor',
            flex: 1,
            filter: true,
            editable: admin,
            hide: !admin,
            cellStyle: params => {
                const backgroundColor = params.data.horseColor;
                return { backgroundColor };
            },
            cellRendererFramework: (params) => (
                admin ? <ColorComponent
                    params={params}
                    onValueChange={(newValue) => handleColorChangeForHorse(params, newValue)}
                /> : ''
            )
        },
        {
            headerName: 'Paddock Opinion',
            field: 'paddockOpinion',
            width: 200,
            filter: true,
            flex: 1,
            cellRendererFramework: (params) => (
                <CustomDropdownComponent
                    value={params.value}
                    data={statusOptions}
                    onValueChange={(newValue) => handlePaddockOpinionChange(params, newValue)}
                />
            )
        },
        // {
        //     headerName: 'Favourites',
        //     field: 'favourite',
        //     flex: 1,
        //     admin: true,
        //     filter: true,
        //     cellRendererFramework: (params) => (
        //         admin ? <CustomDropdownComponent
        //             value={params.value}
        //             data={favstatusOptions}
        //             onValueChange={(newValue) => handlefavouriteChange(params, newValue)}
        //         /> : '')
        // },
        {
            headerName: 'Score',
            field: 'score',
            editable: admin,
            filter: true,
            hide: !admin,
            flex: 1
        }
    ];

    const handleSave = async () => {
        rowData.forEach(async (obj, index) => {
            const firebaseDataName = oddsDateValue + "horseDetails";
            const horseRef = doc(collection(db, firebaseDataName), String(index));
            await setDoc(horseRef, obj);
        });
        setOpen(true);
        setMessage("Data Saved Successfully in Database");
    };


    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);

    };

    const getRowNodeId = (data) => data.id;


    function handleCellValueChanged(params) {
        const newData = [...rowData]; // Assuming gridData is your data source array
        newData[params.rowIndex][params.colDef.field] = params.newValue;
        setRowData(newData); // Update the state with the new data
    }


    const updateHorseData = async (event) => {
        sethorseStatus(event.target.value)
        const firebaseDataName = oddsDateValue + "Race" + raceNumber + "Status";
        const headerRef = doc(db, firebaseDataName, firebaseDataName);
        await setDoc(headerRef, { horseStatusData: event.target.value }, { merge: true });
    }

    const handleCreate = async () => {
        const firebaseDataName = oddsDateValue + "Race" + raceNumber + "StatusText";
        const headerRef = doc(db, firebaseDataName, firebaseDataName);
        await setDoc(headerRef, { text: horseStatusText }, { merge: true });
    };


    const handleHorseChange = (params, newValue) => {
        const updatedRowData = finalJudgementRowData.map((row) => {
            if (params.data.id === row.id) {
                return {
                    ...row,
                    horseNumberFJ: newValue,
                    horseNameFJ: raceHorses.filter(
                        (item) =>
                            item.raceNumber === parseInt(raceNumber) &&
                            item.horseNumber === parseInt(newValue.match(/\d+/))
                    ).map((item) => item.horseName)[0],
                };
            }
            return row;

        });
        setFinalJudgementRowData(updatedRowData);
        updateFirebaseFinalJudgeMent(updatedRowData);

    };

    const updateFirebaseFinalJudgeMent = async (updatedRowData) => {
        updatedRowData.forEach(async (obj, index) => {
            const firebaseDataName = pageName + "finalData";
            const newCityRef = doc(collection(db, firebaseDataName), String(index));
            await setDoc(newCityRef, obj);
        });
    };

    const handleCellEditingStoppedFJ = async (event) => {
        const { data, colDef, newValue, rowIndex, column, api, node } = event;
        api.setFocusedCell(rowIndex, column.colId);
        api.refreshCells({
            rowNodes: [node],
        });
        updateFirebaseFinalJudgeMent(finalJudgementRowData);
    }


    const columnDefsFinalJudgement = [
        {
            headerName: 'Final Judgement',
            field: 'finalJudgement',
            children: [{
                headerName: 'Horse #',
                field: 'horseNumberFJ',
                width: 20,
                cellRendererFramework: (params) => (
                    <CustomDropdownComponent
                        value={params.value}
                        data={horseOptions}
                        onValueChange={(newValue) => handleHorseChange(params, newValue)}
                    />
                )
            },
            {
                headerName: 'Horse Name',
                field: 'horseNameFJ',
                width: 160,

            },
            {
                headerName: 'win',
                field: 'win',
                flex: 1,
                editable: true
            },
            {
                headerName: 'place',
                field: 'place',
                flex: 1,
                editable: true
            }
            ]
        }

    ]

    const handleOpen = () => {
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };


    const handleFileChangePre = (event) => {
        setSelectedFilePre(event.target.files[0]);
    };

    const handleFileChangePost = (event) => {
        setSelectedFilePost(event.target.files[0]);
    };


    const handleUploadPre = async () => {
        const filenamefordb = oddsDateValue + "Race" + raceNumber + "pre";
        const fileExtension = selectedFilePre.name.split('.').pop();
        const fileName = `${filenamefordb}.${fileExtension}`;
        const storageRef = ref(storage, 'raceanalysis/' + fileName);

        // 'file' comes from the Blob or File API
        uploadBytes(storageRef, selectedFilePre).then((snapshot) => {
            setOpen(true);
            setSeverity("success")
            setMessage("File Upload Successfully")

        });

        const firebaseDataName = oddsDateValue + "raceanalysis";
        await addDoc(collection(db, firebaseDataName), { "upload": String(Math.random()) });
    };

    const handleUploadPost = async () => {
        const filenamefordb = oddsDateValue + "Race" + raceNumber + "post";
        const fileExtension = selectedFilePost.name.split('.').pop();
        const fileName = `${filenamefordb}.${fileExtension}`;
        const storageRef = ref(storage, 'raceanalysis/' + fileName);

        // 'file' comes from the Blob or File API
        uploadBytes(storageRef, selectedFilePost).then((snapshot) => {
            setOpen(true);
            setSeverity("success")
            setMessage("File Upload Successfully")

        });

        const firebaseDataName = oddsDateValue + "raceanalysis";
        await addDoc(collection(db, firebaseDataName), { "upload": String(Math.random()) });
        // await setDoc(horseRef, { "uploaded": true }, { merge: true });

    };

    const handleInputChange = (event) => {
        sethorseStatusText(event.target.value);
        setOpen(true);
        setMessage("Data Saved Successfully in Database");
    };

    return (
        <>
            <Button variant="contained" startIcon={<ArrowBackIcon />} onClick={() => navigate("/home")} style={{ margin: '10px' }}>
                Back
            </Button>
            <Grid container direction="row" style={{ marginTop: '10px', marginLeft: '50px' }}>
                <Grid item style={{ border: '1px solid green' }} xs={2}>
                    <DatePickerHorse currDate={currDate} setCurrDate={setCurrDate} />
                </Grid>
                <Grid item style={{ border: '1px solid green' }} xs={10}>
                    <Grid style={{ marginBottom: '10px', marginLeft: '10px' }}>
                        {admin ? <Button
                            variant="contained"
                            size="small"
                            onClick={clearAllRowData}
                            style={{ backgroundColor: 'red', color: 'white', marginRight: '10px' }}
                            startIcon={<DeleteForeverIcon />}
                        >
                            Clear All Row Data
                        </Button> : ''
                        }
                        <Button
                            variant="contained"
                            onClick={handleSave}
                            startIcon={<SaveIcon />}
                            size="small"
                            style={{ backgroundColor: 'blue', color: 'white', marginRight: '10px' }}
                        >
                            Save
                        </Button>
                        <Button
                            size="small"
                            variant="contained"
                            onClick={handleOpen}>
                            Add Excel Data
                        </Button>
                    </Grid>
                    <Grid container direction="column" spacing={2}  >
                        <Grid item>
                            <Tabs value={activeTab} onChange={handleTabChange}>
                                {tabLabels.map((label, index) => (
                                    <Tab key={index} label={label} />
                                ))}
                            </Tabs>

                        </Grid>
                        <Grid item>
                            <Grid container direction="row" spacing={2}>
                                <Grid item xs={8}>
                                    <div className="ag-theme-alpine" style={{ height: 'auto', width: '100%' }}>
                                        <AgGridReact
                                            onGridReady={onGridReady}
                                            rowData={raceHorses}
                                            columnDefs={columnDefs}
                                            animateRows={true}
                                            getRowNodeId={getRowNodeId}
                                            domLayout="autoHeight"
                                            onCellValueChanged={handleCellValueChanged}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={4}>
                                    <Grid container direction="column">
                                        {/* <Grid item xs={4}>
                                        <div className="ag-theme-alpine" style={{ height: 'auto', width: '100%' }}>
                                            <AgGridReact
                                                domLayout="autoHeight"
                                                columnDefs={columnDefsFinalJudgement}
                                                rowData={finalJudgementRowData}
                                                rowHeight={25}
                                                headerHeight={20}
                                                onCellEditingStopped={handleCellEditingStoppedFJ}
                                            />
                                        </div>
                                    </Grid> */}
                                        <Grid item xs={4}>
                                            <InputLabel id="dropdown-label">
                                                Race Status
                                            </InputLabel>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Select
                                                labelId="dropdown-label"
                                                value={horseStatus || ''}
                                                onChange={updateHorseData}
                                                style={{ height: 30 }}
                                            >
                                                {predefinedValues.map((value, index) => (
                                                    <MenuItem key={index} value={value} style={{ fontWeight: 'bold' }}>
                                                        {value}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                size="small"
                                                variant="outlined"
                                                style={{ height: 40 }}
                                                value={horseStatusText}
                                                onChange={handleInputChange}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Button variant="contained" color="primary"
                                                onClick={handleCreate}
                                                size="small">
                                                Save
                                            </Button>
                                        </Grid>
                                        {admin ?
                                            <>
                                                <Grid item xs={4} style={{ border: '1px solid black', width: '400px', padding: '10px', marginTop: '5px' }}>
                                                    <span>Pre Race Analysis</span>
                                                    <Input type="file" onChange={handleFileChangePre} />
                                                    <Button variant="contained" color="primary" onClick={handleUploadPre}>
                                                        Upload Pre Race
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={4} style={{ border: '1px solid black', width: '400px', padding: '10px', marginTop: '10px' }}>
                                                    <span>Final Judgement</span>
                                                    <Input type="file" onChange={handleFileChangePost} />
                                                    <Button variant="contained" color="primary" onClick={handleUploadPost}>
                                                        Upload Final Judgement
                                                    </Button>
                                                </Grid>
                                            </>
                                            : ''}
                                        

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Dialog open={dialogOpen} onClose={handleCloseDialog}>
                    <DialogTitle>Add Excel Data</DialogTitle>
                    <DialogContent>
                        <ExcelManager handleAddRow={handleAddRow} textValue={textValue} handleTextChange={handleTextChange} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialog}>Close</Button>
                    </DialogActions>
                </Dialog>

                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                        {message}
                    </Alert>
                </Snackbar>
            </Grid>
        </>
    );
};

export default RaceHorseManagement;
