import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import BedroomBabyIcon from '@mui/icons-material/BedroomBaby';
import { useNavigate } from 'react-router-dom';
import { auth } from './firebase';
import { Home, ExitToApp } from '@mui/icons-material';
import logo from './logo11.png';

const pages = Array.from({ length: 10 }, (_, index) => `Race${index + 1}`);
const settings = ['Logout'];

function TopBar() {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const navigate = useNavigate();

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };


    const handleCloseNavMenu = (page) => () => {
        navigate('/odds', { state: { inputValue: page } });
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = async () => {
        await auth.signOut();
        navigate("/login");
        setAnchorElUser(null);
    };
    const styles = {
        customizeToolbar: {
            minHeight: 20,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
        logoStyle: {
            marginRight: 10,
            maxHeight: 40,
            maxWidth: '100%',
        }
    };

    const handleLogout = async () => {
        await auth.signOut();
        navigate("/login");
    };


    return (
        <AppBar position="static">
            <Toolbar style={styles.customizeToolbar}>
                <div>
                    <div style={{ display: 'flex', alignItems: 'center' , height:'50px' }}>
                        <IconButton edge="start" color="inherit" aria-label="home" href="/">
                            {/* <Home /> */}
                            <img src={logo} alt="Logo" style={{ width:'80px',height:'80px', marginTop:'30px'  }} />
                        </IconButton>
                        <Typography noWrap
                            component="a"
                            href="/"
                            variant="h6"
                            sx={{
                                ml: 1,
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            ASHWA VAARTE
                        </Typography>
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="body2" component="div" sx={{ textAlign: 'right' }}>
                        Logout
                    </Typography>
                    <IconButton color="inherit" aria-label="logout" onClick={handleLogout}>
                        <ExitToApp />
                    </IconButton>
                </div>
            </Toolbar>
        </AppBar>
    );
}
export default TopBar;
