import { Grid, Button, TextField, Snackbar, Alert } from "@mui/material";
import { useState, useEffect } from "react";
import dayjs from 'dayjs';
import { db } from './firebase';
import { query, onSnapshot, collection, setDoc, doc } from "firebase/firestore";

export const Links = () => {
    const [youtubeText, setYoutubeText] = useState();
    const [updateText, setUpdateText] = useState();
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [oddsDateValue, setOddsDateValue] = useState(dayjs(new Date()).format('DDMMYYYY'));

    useEffect(() => {
        const textInputValueFb = query(collection(db, oddsDateValue + "link"));
        const unsubscribeStatusText = onSnapshot(textInputValueFb, (querySnapshot) => {
            const linkTextFb = [];
            querySnapshot.forEach((doc) => {
                linkTextFb.push(doc.data());
            });
            linkTextFb.length > 0 ? setYoutubeText(linkTextFb[0]?.text) : setYoutubeText('');
        });
        const textInputValueFbUpdate = query(collection(db, oddsDateValue + "updateText"));
        const unsubscribeStatusTextUpdate = onSnapshot(textInputValueFbUpdate, (querySnapshot) => {
            const linkTextFbUpdate = [];
            querySnapshot.forEach((doc) => {
                linkTextFbUpdate.push(doc.data());
            });
            linkTextFbUpdate.length > 0 ? setUpdateText(linkTextFbUpdate[0]?.text) : setUpdateText('');
        });
    }, [oddsDateValue]);
    return (
        <Grid container direction="column" justifyContent="center" style={{ marginTop: '100px' }}>
            <Grid item style={{ width: '100%', maxWidth: '600px' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <h1>Youtube Live Stream Link</h1>
                </div>
                <TextField
                    size="small"
                    variant="outlined"
                    style={{ height: 40, width: '100%' }}
                    value={youtubeText}
                    onChange={(event) => {
                        setYoutubeText(event.target.value);
                    }}
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={async () => {
                        const firebaseDataName = oddsDateValue + "link";
                        const headerRef = doc(db, firebaseDataName, firebaseDataName);
                        await setDoc(headerRef, { text: youtubeText }, { merge: true });
                        setOpen(true);
                        setMessage("Link Saved Successfully in Database");
                    }}
                    size="small"
                    style={{ width: '20%' }}
                >
                    Save Link
                </Button>
            </Grid>

            <Grid item style={{ width: '100%', maxWidth: '600px' }}> {/* Adjust the max-width as needed */}
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <h1>Message</h1>
                </div>
                <TextField
                    size="small"
                    variant="outlined"
                    style={{ height: 40, width: '100%' }}
                    value={updateText}
                    multiline
                    maxRows={4}          
                    onChange={(event) => {
                        setUpdateText(event.target.value);
                    }}
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={async () => {
                        const firebaseDataName = oddsDateValue + "updateText";
                        const headerRef = doc(db, firebaseDataName, firebaseDataName);
                        await setDoc(headerRef, { text: updateText }, { merge: true });
                        setOpen(true);
                        setMessage("Data Saved Successfully in Database");
                    }}
                    size="small"
                    style={{ width: '30%',marginTop: 30  }}
                >
                    Save Message
                </Button>
            </Grid>

            <Snackbar open={open} autoHideDuration={6000} onClose={() => setOpen(false)}>
                <Alert onClose={() => setOpen(false)} severity={"success"} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        </Grid>

    )
}